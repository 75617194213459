<template>
  <div>
    <b-modal
      id="payment-metamask-modal-3"
      ref="payment-metamask-modal-3"
      class="payment_complate_modal_wrap"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <CRow>
        <CCol class="col-12 py-2">
          <CLink @click="hide()">
            <img class="float-right" src="/img/threespace/icon/x-icon.png" />
          </CLink>
        </CCol>
      </CRow>

      <div class="payment_complete_modal">
        <div class="title">
          <p v-html="$t('payment.completePayment')"></p>
        </div>
        <div class="content">
          <b-table-simple borderless>
            <!-- 작품명 -->
            <tr>
              <th>{{ $t("payment.artwork.title") }}</th>
              <td>
                {{ paymentResultInfo.goodsName }}
              </td>
            </tr>
            <!-- 소유주 -->
            <tr>
              <th>{{ $t("payment.metamask3.ownership") }}</th>
              <td>
                <a
                  v-if="paymentResultInfo.klaytnScopeUrl !== ''"
                  :href="paymentResultInfo.klaytnScopeUrl"
                  target="_blank"
                >
                  {{ $t("payment.metamask3.etherscan") }}
                </a>
                <p v-else>
                  {{ $t("payment.metamask3.etherscan") }}
                </p>
              </td>
            </tr>
            <!-- 가격 -->
            <tr>
              <th>{{ $t("payment.artwork.price") }}</th>
              <td>
                {{ paymentResultInfo.amountCoin | currencyCoin("ETH") }} {{ paymentResultInfo.currency }}
              </td>
            </tr>
            <!-- 주문일 -->
            <tr>
              <th>{{ $t("payment.metamask3.orderDate") }}</th>
              <td>
                {{ paymentResultInfo.orderDate | moment("YYYY-MM-DD HH:mm:ss") }}
              </td>
            </tr>
          </b-table-simple>
        </div>
        <div class="btn_box">
          <b-button
            class="goCollection"
            @click="goMyPage"
          >
            {{ $t("payment.goto.myPage") }}
          </b-button>
          <b-button
            class="goArtpool"
            @click="goArtpool"
          >
            {{ $t("payment.goArtPool") }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PaymentMetaMask3",
  props: {
    paymentResultInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    return {
    };
  },
  computed: {
    ...mapState({
      me: state => {
        return state.auth.me || {};
      }
    })
  },
  mounted() {},
  methods: {
    show() {
      this.$refs["payment-metamask-modal-3"].show();
    },
    hide() {
      this.$router.push({ name: "Drops" });
      this.$refs["payment-metamask-modal-3"].hide();
    },
    goMyPage() {
      if (this.me.idx > 0) {
        this.$router.push({
          name: "MyPage",
          params: { idxMember: this.me.idx, activeTab: 2 }
        });
      } else {
        this.$router.push({ name: "Drops" });
      }
      this.$refs["payment-metamask-modal-3"].hide();
    },
    goDrops() {
      this.$router.push({ name: "Drops" });
      this.$refs["payment-metamask-modal-3"].hide();
    },
    goArtpool() {
      this.$router.push({ name: "NftPool" });
      this.$refs["payment-metamask-modal-3"].hide();
    },
  },
  filters: {
    currency: value => {
      if (!value) return "";
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    currencyCoin: function(value,currency){
      if(currency === "ETH"){
        return parseFloat(Math.floor(value * 1000) / 1000)
          .toFixed(3)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
      }

      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  }
};
</script>
