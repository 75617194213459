<template>
  <div>
    <b-modal
      id="payment-metamask-modal-2"
      ref="payment-metamask-modal-2"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-black"
      @hide="closeModal"
    >
      <CRow>
        <CCol class="col-12">
          <CLink @click="hideConfirm">
            <CIcon name="cil-x" class="float-right" size="lg" />
          </CLink>
        </CCol>
      </CRow>

      <div class="w-100 d-flex justify-content-center align-items-center">
        <img src="/img/threespace/company/metamask_logo.png" width="34" class="float-left mr-1"/>
        <h5 class="ml-2 mb-0">{{ $t("wallet.modal.metaMaskTitle") }}</h5>
      </div>
      <div class="w-100 d-flex justify-content-center py-5">
        MetaMask 트랜젝션 확인 후 결제를 진행해 주세요.
      </div>
      <div class="w-100 text-center">
        <p>
          <span class="text-gray mr-2">{{ $t("wallet.modal.remainTime") }}</span>
          <span class="text-danger">{{ time }}</span>
        </p>
      </div>
    </b-modal>

    <PaymentMetaMask3 :payment-result-info="paymentResultInfo" ref="paymentMetaMask3" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const paymentHelper = createNamespacedHelpers("payment");
import Web3 from "web3";

import PaymentMetaMask3 from "@/views/threespace/payment/PaymentMetaMask3";

export default {
  name: "PaymentMetaMask2",
  components: {
    PaymentMetaMask3
  },
  props: {
    paymentInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    return {
      isTimeout: false,
      date: this.$moment(60 * 3 * 1000),
      paymentResultInfo: {},
      paymentType: "",
      resultStatus: "",
    };
  },
  mounted() {},
  computed: {
    time: function () {
      return this.date.format("mm : ss");
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }
  },
  methods: {
    ...paymentHelper.mapActions([
      "completePaymentEthPaycontract",
      "completePaymentMaticPaycontract",
      "prepareEthPayment",
      "prepareMaticPayment",
      "updatePaymentEth",
      "updatePaymentMatic",
    ]),
    showMetaMaskPayment() {
      this.$store.commit("setShowLoading", {
        show: true,
        msg: this.$t("loading.payment.metamask.prepared")
      }); // loading overlay
      if (this.paymentType === "ETH") {
        this.prepareEthPayment({
          payment: this.paymentInfo.idxProduct,
          currency: "ETH",
        })
          .then((receipt) => {
            this.$log.info("[prepareEthPayment] receipt :", receipt);
            this.$store.commit("setShowLoading", {show: false, msg: ""}); // loading overlay
            this.sendNext(receipt);
          })
          .catch((error) => {
            this.$log.error(error);
            this.hideReserveCancel();
            this.$store.commit("setShowLoading", {show: false, msg: ""}); // loading overlay
            alert(this.$t("payment.payError.fail"));
          });
      } else if (this.paymentType === "MATIC") {
        this.prepareMaticPayment({
          payment: this.paymentInfo.idxProduct,
          currency: "MATIC",
        })
          .then((receipt) => {
            this.$log.info("[prepareMaticPayment] receipt :", receipt);
            this.$store.commit("setShowLoading", {show: false, msg: ""}); // loading overlay
            this.sendNext(receipt);
          })
          .catch((error) => {
            this.$log.error(error);
            this.hideReserveCancel();
            this.$store.commit("setShowLoading", {show: false, msg: ""}); // loading overlay
            alert(this.$t("payment.payError.fail"));
          });
      }
    },
    sendNext(receipt) {
      if (receipt.buyable) {
        const web3 = new Web3(window.ethereum);
        this.isTimeout = false;
        this.date = this.$moment(60 * 3 * 1000); // 3분
        clearInterval(this.totalTimer);
        // 결제 대기 타이머
        this.setTotalTimer();
        this.$refs["payment-metamask-modal-2"].show();

        const getData = web3.eth.abi.encodeFunctionCall(
          {
            "name": "purchase",
            "type": "function",
            "inputs": [
              {
                "internalType": "address",
                "name": "contractAddr",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
              }
            ],
          },
          [receipt.nftContract, receipt.tokenId]
        );
        
        // eth, matic pay contract setting
        let nftAddress = "";
        if (this.paymentType === "ETH") {
          nftAddress = process.env.VUE_APP_ETHEREUM_PAY_CONTRACT
        } else if (this.paymentType === "MATIC") {
          nftAddress = process.env.VUE_APP_POLYGON_PAY_CONTRACT
        }

        // finally pass this data parameter to send Transaction
        web3.eth.sendTransaction({
            from: receipt.buyerAddress,
            to: nftAddress,
            value: receipt.amount,
            gas: 200000,
            data: getData,
          })
          .on('transactionHash', (hash) => {
            this.$refs["payment-metamask-modal-2"].hide();
            this.$store.commit("setShowLoading", {
              show: true,
              msg: this.$t("loading.payment.metamask.progressing")
            }); // loading overlay
          })
          .on('receipt', (result) => {
            this.$log.info("sendTransaction result", result);
            if (result.status) {
              clearInterval(this.totalTimer);
              this.isTimeout = true;
              this.requestComplate();
            } else {
              this.$log.error("sendTransaction error", result);
              this.hideReserveCancel();
              alert(this.$t("payment.payError.fail"));
            }
            this.$store.commit("setShowLoading", {show: false, msg: ""}); // loading overlay
          }).on('error', (error) => {
            this.$log.error("sendTransaction error", error);
            this.hideReserveCancel();
            this.$store.commit("setShowLoading", {show: false, msg: ""}); // loading overlay
            alert(this.$t("payment.payError.fail"));
          });
      } else {
        let errorMessage = "현재 네트워크 상태가 원활하지 않습니다. 잠시 후 다시 시도해 주세요.";
        if (receipt.sold) {
          errorMessage = "이미 팔린 작품입니다.";
        } else if (!receipt.buyable) {
          errorMessage = "현재 구매할 수 없는 작품입니다";
        }
        this.hideReserveCancel();
        alert(errorMessage);
      }
    },
    setTotalTimer() {
      if (!this.isTimeout) {
        this.totalTimer = setInterval(() => {
          this.date = this.date.clone().subtract(1, "seconds");
          if (this.time === "00 : 00") {
            clearInterval(this.totalTimer);
            this.$log.log("timeout..."); //TODO
            this.hideReserveCancel();
          }
        }, 1000);
      }
    },
    requestComplate() {
      if (this.paymentType === "ETH") {
        this.completePaymentEthPaycontract(this.paymentInfo)
          .then(data => {
            //this.$log.log("[completePaymentEthPaycontract] >> ", data);
            this.paymentResultInfo = data;
            this.$refs["paymentMetaMask3"].show();
            this.$refs["payment-metamask-modal-2"].hide();
  
            // 판매 법인 정보 삭제
            localStorage.removeItem("referralCorpSeller_" + this.paymentResultInfo.idxProduct);
          })
          .catch(error => {
            this.$log.error(error);
            this.$refs["payment-metamask-modal-2"].hide();
            alert(this.$t("payment.payError.waiting"));
          });
      } else if (this.paymentType === "MATIC") {
        this.completePaymentMaticPaycontract(this.paymentInfo)
          .then(data => {
            //this.$log.log("[completePaymentMaticPaycontract] >> ", data);
            this.paymentResultInfo = data;
            this.$refs["paymentMetaMask3"].show();
            this.$refs["payment-metamask-modal-2"].hide();
  
            // 판매 법인 정보 삭제
            localStorage.removeItem("referralCorpSeller_" + this.paymentResultInfo.idxProduct);
          })
          .catch(error => {
            this.$log.error(error);
            this.$refs["payment-metamask-modal-2"].hide();
            alert(this.$t("payment.payError.waiting"));
          });
      }
    },
    show(paymentType) {
      this.isTimeout = false;
      this.paymentType = paymentType;
      this.showMetaMaskPayment();
    },
    hideReserveCancel() {
      const requestInfo = {
        idxProduct: this.paymentInfo.idxProduct,
        idxOrderPayment: this.paymentInfo.idxOrderPayment,
        status: "RESERVE_CANCEL"
      };
      // 작품 상태(예약취소, 판매중) 업데이트
      if (this.paymentType === "ETH") {
        this.updatePaymentEth(requestInfo)
          .then(data => {
            this.$refs["payment-metamask-modal-2"].hide();
          })
          .catch(error => {
            this.$log.error(error);
            this.$refs["payment-metamask-modal-2"].hide();
          });
      } else if (this.paymentType === "MATIC") {
        this.updatePaymentMatic(requestInfo)
          .then(data => {
            this.$refs["payment-metamask-modal-2"].hide();
          })
          .catch(error => {
            this.$log.error(error);
            this.$refs["payment-metamask-modal-2"].hide();
          });
      }
    },
    hideConfirm() {
      if (confirm(this.$t("payment.payCancelMsg"))) {
        this.hideReserveCancel();
      }
    },
    closeModal() {
      clearInterval(this.totalTimer);
    },
  },
  destroyed() {
    clearInterval(this.totalTimer);
  }
};
</script>
