<template>
  <div>
    <b-modal
      id="payment-kaikas-modal-1"
      ref="payment-kaikas-modal-1"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-black"
      @hide="closeModal"
    >
      <CRow>
        <CCol class="col-12">
          <CLink @click="hide">
            <CIcon name="cil-x" class="float-right" size="lg" />
          </CLink>
        </CCol>
      </CRow>

      <div class="w-100 d-flex justify-content-center align-items-center">
        <img src="/img/threespace/company/kaia-wallet.png" width="37" class="float-left mr-1"/>
        <h5 class="ml-2 mb-0">
          {{ $t("payment.kaikas.connectTitle") }}
        </h5>
      </div>
      <div class="mt-3 px-4">
        <p v-html="$t('payment.kaikas.connectDesc')" style="word-break: keep-all;"></p>
      </div>
      <div class="text-center mt-3">
        <b-form-checkbox v-model="checkNotice">
          {{ $t("payment.kaikas.agree") }}
        </b-form-checkbox>
      </div>
      <div class="my-4 d-flex justify-content-center">
        <b-button squared class="px-4" @click="hide">
          <span>{{ $t("button.cancel") }}</span>
        </b-button>
        <b-button
          variant="primary"
          squared
          class="ml-3 px-4"
          @click="showPaymentKaikas2"
          :disabled="timeout || !checkNotice"
        >
          <span class="text-white">{{ $t("button.yesPayment") }}</span>
        </b-button>
      </div>
    </b-modal>

    <PaymentKaikas2
      :kaikas-payment-info="kaikasPaymentInfo"
      ref="paymentKaikas2"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Caver from "caver-js";
import PaymentKaikas2 from "./PaymentKaikas2";
const paymentHelper = createNamespacedHelpers("payment");

export default {
  name: "PaymentKaikas1",
  components: {
    PaymentKaikas2,
  },
  props: {
    orderPaymentInfo: {
      type: Object,
      default: () => {}
    },
    paymentProductInfo: {
      type: Object,
      default: () => {}
    },
    memberInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      kaikasPaymentInfo: this.getInitData(),
      time: 60,
      timeout: false,
      paymentType: "",
      checkNotice: false,
    };
  },
  mounted() {},
  computed: {
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  methods: {
    ...paymentHelper.mapActions(["updatePaymentKlay", "prepareTokenPayment"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    show(paymentType) {
      this.time = 60;
      this.timeout = false;
      this.setTotalTimer();
      if (paymentType != undefined) {
        this.paymentType = paymentType;
      }
      this.kaikasPaymentInfo = this.getInitData();
      this.$refs["payment-kaikas-modal-1"].show();
    },
    async showPaymentKaikas2() {
      if (window.klaytn === undefined || !window.klaytn) {
        alert("We can't find kaikas wallet");
        return;
      } else {
        const accounts = await window.klaytn.enable();
        let account = accounts.length > 0 ? accounts [0] : null

        if(account !== null ) {
          if(account.toLowerCase() !== this.memberInfo.klaytnAddr.toLowerCase()) {
            alert("Check your connected wallet address again");
            return;
          }
        } else {
          alert("Check your connected wallet address again");
          return;
        }
      }

      if (
        window.klaytn.networkVersion === undefined ||
        window.klaytn.networkVersion === null ||
        window.klaytn.networkVersion.toString() !== process.env.VUE_APP_KLAYTN_CHAIN_ID
      ) {
        alert("Check your connected network again");
        return;
      }

      this.kaikasPaymentInfo.idxProduct = this.orderPaymentInfo.idxProduct;
      this.kaikasPaymentInfo.idxMember = this.orderPaymentInfo.idxMember;
      this.kaikasPaymentInfo.idxOrderPayment = this.orderPaymentInfo.idxOrderPayment;

      // 작품 상태(예약중) 업데이트
      this.updatePaymentKlay(this.kaikasPaymentInfo)
        .then((data) => {
          this.kaikasPaymentInfo.fromAddr = data.fromAddr;
          this.kaikasPaymentInfo.toAddr = data.toAddr;
          this.kaikasPaymentInfo.amountCoin = data.amountCoin;

          clearInterval(this.totalTimer);
          this.$refs["paymentKaikas2"].show(this.paymentType);
          this.$refs["payment-kaikas-modal-1"].hide();
        })
        .catch(error => {
          this.$log.error(error);
          alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
          clearInterval(this.totalTimer);
          this.$refs["payment-kaikas-modal-1"].hide();
        });
    },
    hide() {
      if (confirm(this.$t("payment.payCancelMsg"))) {
        clearInterval(this.totalTimer);
        this.$refs["payment-kaikas-modal-1"].hide();
      }
    },
    closeModal() {
      clearInterval(this.totalTimer);
    },
    getInitData() {
      return {
        idxProduct: null,
        idxMember: null,
        idxOrderPayment: null,
        status: "RESERVE",
        fromAddr: null,
        toAddr: null,
        amountCoin: null,
        coinCurrencyType: this.paymentType,
      }
    },
    cancelPayment() {
      const requestInfo = {
        idxProduct: this.kaikasPaymentInfo.idxProduct,
        idxOrderPayment: this.kaikasPaymentInfo.idxOrderPayment,
        status: "RESERVE_CANCEL"
      };

      // 작품 상태(예약취소, 판매중) 업데이트
      this.updatePaymentKlay(requestInfo);
      this.$refs["payment-kaikas-modal-1"].hide();
    },
    setTotalTimer() {
      this.totalTimer = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          clearInterval(this.totalTimer);
          this.timeout = true;
        }
      }, 1000);
    },
  },
  filters: {
    currencyCoin: function(value,currency){
      if(currency === "ETH"){
        return parseFloat(Math.floor(value * 1000) / 1000)
          .toFixed(3)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
      }

      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }
};
</script>
