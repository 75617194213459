<template>
  <div>
    <b-modal
      id="payment-matamask-modal-1"
      ref="payment-matamask-modal-1"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-black"
      @hide="closeModal"
    >
      <CRow>
        <CCol class="col-12">
          <CLink @click="hide">
            <CIcon name="cil-x" class="float-right" size="lg" />
          </CLink>
        </CCol>
      </CRow>

      <div class="w-100 d-flex justify-content-center align-items-center">
        <img src="/img/threespace/company/metamask_logo.png" width="34" class="float-left mr-1"/>
        <h5 class="ml-2 mb-0">
          {{ $t("payment.metamask.connectTitle") }}
        </h5>
      </div>
      <div class="text-center mt-3 px-5">
        <p>
          {{ $t("payment.metamask.connectDesc") }}
        </p>
      </div>
      <div class="my-4 d-flex justify-content-center">
        <b-button squared class="px-4" @click="hide">
          <span>{{ $t("button.cancel") }}</span>
        </b-button>
        <b-button
          variant="primary"
          squared
          class="ml-3 px-4"
          @click="showPaymentMetaMask2"
          :disabled="timeout"
        >
          <span class="text-white">{{ $t("button.yesPayment") }}</span>
        </b-button>
      </div>
    </b-modal>

    <PaymentMetaMask2
      :payment-info="paymentInfo"
      ref="paymentMetaMask2"
    />
    <Metamask ref="metamask"/>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import PaymentMetaMask2 from "./PaymentMetaMask2";
import Metamask from "../../../utils/metamask";
const paymentHelper = createNamespacedHelpers("payment");

export default {
  name: "PaymentMetaMask1",
  components: {
    Metamask,
    PaymentMetaMask2,
  },
  props: {
    orderPaymentInfo: {
      type: Object,
      default: () => {}
    },
    paymentProductInfo: {
      type: Object,
      default: () => {}
    },
    memberInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      paymentInfo: this.getInitData(),
      time: 60,
      timeout: false,
      paymentType: "",
    };
  },
  mounted() {},
  computed: {
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
  },
  methods: {
    ...paymentHelper.mapActions([
      "updatePaymentEth", 
      "updatePaymentMatic"
    ]),
    show(paymentType) {
      this.time = 60;
      this.timeout = false;
      this.setTotalTimer();
      if (paymentType != undefined) {
        this.paymentType = paymentType;
      }
      this.paymentInfo = this.getInitData();
      this.$refs["payment-matamask-modal-1"].show();
    },
    async showPaymentMetaMask2() {
      let memberAddress = "";
      let chainId = 0;
      if (this.paymentType === "ETH") {
        memberAddress = this.memberInfo.ethereumAddr;
        chainId = process.env.VUE_APP_ETHEREUM_CHAIN_ID
      } else if (this.paymentType === "MATIC") {
        memberAddress = this.memberInfo.polygonAddr;
        chainId = process.env.VUE_APP_POLYGON_CHAIN_ID
      }
      if (window.ethereum === undefined || !window.ethereum) {
        alert("We can't find MetaMask wallet");
        return;
      } else {
        const accounts = await window.ethereum.enable();
        let account = accounts.length > 0 ? accounts [0] : null;

        if(account !== null ) {
          if(account.toLowerCase() !== memberAddress.toLowerCase()) {
            alert("Check your connected wallet address again");
            return;
          }
        } else {
          alert("Check your connected wallet address again");
          return;
        }
      }

      if (
        window.ethereum.networkVersion.toString() !== chainId ||
        window.ethereum.networkVersion === null ||
        window.ethereum.networkVersion === undefined
      ) {
        await this.$refs["metamask"].changeNetwork(chainId)
      }

      this.paymentInfo.idxProduct = this.orderPaymentInfo.idxProduct;
      this.paymentInfo.idxMember = this.orderPaymentInfo.idxMember;
      this.paymentInfo.idxOrderPayment = this.orderPaymentInfo.idxOrderPayment;

      if (this.paymentType === "ETH") {
        // 작품 상태(예약중) 업데이트
        this.updatePaymentEth(this.paymentInfo)
          .then((data) => {
            this.paymentInfo.fromAddr = data.fromAddr;
            this.paymentInfo.toAddr = data.toAddr;
            this.paymentInfo.amountCoin = data.amountCoin;
  
            clearInterval(this.totalTimer);
            this.$refs["paymentMetaMask2"].show(this.paymentType);
            this.$refs["payment-matamask-modal-1"].hide();
          })
          .catch(error => {
            this.$log.error(error);
            alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
            clearInterval(this.totalTimer);
            this.cancelPayment();
          });
      } else if (this.paymentType === "MATIC") {
        // 작품 상태(예약중) 업데이트
        this.updatePaymentMatic(this.paymentInfo)
          .then((data) => {
            this.paymentInfo.fromAddr = data.fromAddr;
            this.paymentInfo.toAddr = data.toAddr;
            this.paymentInfo.amountCoin = data.amountCoin;
  
            clearInterval(this.totalTimer);
            this.$refs["paymentMetaMask2"].show(this.paymentType);
            this.$refs["payment-matamask-modal-1"].hide();
          })
          .catch(error => {
            this.$log.error(error);
            alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
            clearInterval(this.totalTimer);
            this.cancelPayment();
          });
      }

    },
    hide() {
      if (confirm(this.$t("payment.payCancelMsg"))) {
        clearInterval(this.totalTimer);
        this.$refs["payment-matamask-modal-1"].hide();
      }
    },
    closeModal() {
      clearInterval(this.totalTimer);
    },
    getInitData() {
      return {
        idxProduct: null,
        idxMember: null,
        idxOrderPayment: null,
        status: "RESERVE",
        fromAddr: null,
        toAddr: null,
        amountCoin: null,
        coinCurrencyType: this.paymentType,
      }
    },
    cancelPayment() {
      const requestInfo = {
        idxProduct: this.paymentInfo.idxProduct,
        idxOrderPayment: this.paymentInfo.idxOrderPayment,
        status: "RESERVE_CANCEL"
      };
      // 작품 상태(예약취소, 판매중) 업데이트
      if (this.paymentType === "ETH") {
        this.updatePaymentEth(requestInfo);
      } else if (this.paymentType === "MATIC") {
        this.updatePaymentMatic(requestInfo);
      }
      this.$refs["payment-matamask-modal-1"].hide();
    },
    setTotalTimer() {
      this.totalTimer = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          clearInterval(this.totalTimer);
          this.timeout = true;
        }
      }, 1000);
    },
  },
};
</script>
