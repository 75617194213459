<template>
  <div>
    <b-modal
      id="payment-neopin-modal-2"
      ref="payment-neopin-modal-2"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-black"
      @hide="closeModal"
    >
      <CRow>
        <CCol class="col-12">
          <CLink @click="hideConfirm">
            <CIcon name="cil-x" class="float-right" size="lg" />
          </CLink>
        </CCol>
      </CRow>

      <div class="w-100 d-flex justify-content-center align-items-center">
        <img src="/img/threespace/company/neopin.png" width="34" class="float-left mr-1"/>
        <h5 class="ml-2 mb-0">{{ $t("wallet.modal.neopinTitle") }}</h5>
      </div>
      <div class="w-100 d-flex justify-content-center py-5">
        Neopin 지갑에서 트랜젝션 확인 후 결제를 진행해 주세요. (TODO 번역)
      </div>
      <div class="w-100 text-center">
        <p>
          <span class="text-gray mr-2">{{ $t("wallet.modal.remainTime") }}</span>
          <span class="text-danger">{{ time }}</span>
        </p>
      </div>
    </b-modal>

    <PaymentNeopin3 :payment-result-info="paymentResultInfo" ref="paymentNeopin3" />
  </div>
</template>

<script>
import {createNamespacedHelpers, mapActions, mapState} from "vuex";
const paymentHelper = createNamespacedHelpers("payment");
import Web3 from "web3";
import NeopinConnect from "nptconnect-client";
import PaymentNeopin3 from "@/views/threespace/payment/PaymentNeopin3";
import Caver from "caver-js";


export default {
  name: "PaymentWalletConnect2",
  components: {
    PaymentNeopin3
  },
  props: {
    paymentInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    return {
      isTimeout: false,
      date: this.$moment(60 * 3 * 1000),
      paymentResultInfo: {},
      paymentType: "",
      resultStatus: "",
    };
  },
  mounted() {},
  computed: {
    ...mapState(["wallet"]),
    time: function () {
      return this.date.format("mm : ss");
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }
  },
  methods: {
    ...paymentHelper.mapActions([
      "completePaymentKlayPaycontract",
      "prepareTokenPayment",
      "updatePaymentKlay",
    ]),
    ...mapActions("wallet", ["getNonce"]),
    showWalletConnectPayment() {
      this.prepareTokenPayment({
        payment: this.paymentInfo.idxProduct,
        currency: "KLAY",
      })
        .then((receipt) => {
          this.$log.info("[prepareTokenPayment] receipt :", receipt);
          this.sendNext(receipt);
        })
        .catch((error) => {
          this.$log.error(error);
          alert(this.$t("payment.payError.fail"));
        });
    },
    sendNext(receipt) {
      if (receipt.buyable) {
        this.isTimeout = false;
        this.date = this.$moment(60 * 3 * 1000); // 3분
        clearInterval(this.totalTimer);
        // 결제 대기 타이머
        this.setTotalTimer();
        this.$refs["payment-neopin-modal-2"].show();

        const session = this.getCachedSession();

        if(session){
          const connector = new NeopinConnect({ session })
          const caver = new Caver();
          const data = caver.klay.abi.encodeFunctionCall(
            {
              "name": "purchase",
              "type": "function",
              "inputs": [
                {
                  "internalType": "address",
                  "name": "contractAddr",
                  "type": "address"
                },
                {
                  "internalType": "uint256",
                  "name": "tokenId",
                  "type": "uint256"
                }
              ],
            },
            [receipt.nftContract, receipt.tokenId]
          );

          this.getNonce(receipt.buyerAddress).then((nonce) => {
            const tx = {
              from: receipt.buyerAddress,
              to: process.env.VUE_APP_KLAYTN_PAY_CONTRACT,
              value: receipt.amount,
              gas: 100000,
              gasPrice: caver.utils.convertToPeb(750, "Ston"),
              nonce,
              data
            }

            connector.sendTransaction(tx)
              .then((result)  => {
                this.$log.info("Neopin sendTransaction : ", result);
                clearInterval(this.totalTimer);
                this.isTimeout = true;
                this.requestComplate();
              })
              .catch((error) => {
                this.$log.error("Neopin sendTransaction : ", error);
                this.hideReserveCancel();
                this.$store.commit("setShowLoading", {show: false, msg: ""}); // loading overlay
                alert(this.$t("payment.payError.fail"));
              })

          }).catch((error) => {
            this.$log.error("Can't Get Nonce : ", error);
            this.hideReserveCancel();
            this.$store.commit("setShowLoading", {show: false, msg: ""}); // loading overlay
            alert(this.$t("payment.payError.fail"));
          })
        }
      } else {
        let errorMessage = "현재 카이아 네트워크 상태가 원활하지 않습니다. 잠시 후 다시 시도해 주세요.";
        if (receipt.sold) {
          errorMessage = "이미 팔린 작품입니다.";
        } else if (!receipt.buyable) {
          errorMessage = "현재 구매할 수 없는 작품입니다";
        }
        this.hideReserveCancel();
        alert(errorMessage);
      }
    },
    getCachedSession(){
      const local = localStorage ? localStorage.getItem("walletconnect") : null;

      let session = null;
      if (local) {
        session = JSON.parse(local);
      }
      return session;
    },
    setTotalTimer() {
      if (!this.isTimeout) {
        this.totalTimer = setInterval(() => {
          this.date = this.date.clone().subtract(1, "seconds");
          if (this.time === "00 : 00") {
            clearInterval(this.totalTimer);
            this.$log.log("timeout..."); //TODO
            this.hideReserveCancel();
          }
        }, 1000);
      }
    },
    requestComplate() {
      this.completePaymentKlayPaycontract(this.paymentInfo)
        .then(data => {
          //this.$log.log("[completePaymentEthPaycontract] >> ", data);
          this.paymentResultInfo = data;
          this.$refs["paymentNeopin3"].show();
          this.$refs["payment-neopin-modal-2"].hide();

          // 판매 법인 정보 삭제
          localStorage.removeItem("referralCorpSeller_" + this.paymentResultInfo.idxProduct);
        })
        .catch(error => {
          this.$log.error(error);
          this.$refs["payment-neopin-modal-2"].hide();
          alert(this.$t("payment.payError.waiting"));
        });
    },
    show(paymentType) {
      this.isTimeout = false;
      this.paymentType = paymentType;
      this.showWalletConnectPayment();
    },
    hideReserveCancel() {
      const requestInfo = {
        idxProduct: this.paymentInfo.idxProduct,
        idxOrderPayment: this.paymentInfo.idxOrderPayment,
        status: "RESERVE_CANCEL"
      };
      // 작품 상태(예약취소, 판매중) 업데이트
      this.updatePaymentKlay(requestInfo)
        .then(data => {
          this.$refs["payment-neopin-modal-2"].hide();
        })
        .catch(error => {
          this.$refs["payment-neopin-modal-2"].hide();
          this.$log.error(error);
        });
    },
    hideConfirm() {
      if (confirm(this.$t("payment.payCancelMsg"))) {
        this.hideReserveCancel();
      }
    },
    closeModal() {
      clearInterval(this.totalTimer);
    },
  },
  destroyed() {
    clearInterval(this.totalTimer);
  }
};
</script>
