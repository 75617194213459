<template>
  <div>
    <div id="ProductDetailWrap" class="payment_wrap">
      <!-- main media -->
      <div class="pd_main">
        <template v-if="isProductFileTypeImage(paymentProductInfo.productFileType)">
          <b-img
            id="productItemImage"
            ref="productItemImage"
            :src="replaceDisplayDetailUrl(paymentProductInfo)"
            @contextmenu.prevent.stop
          ></b-img>
        </template>
        <template v-else>
          <video
            id="productItemVideo"
            ref="productItemVideo"
            :poster="replaceDisplayPosterUrl(paymentProductInfo)"
            :src="replaceDisplayDetailUrl(paymentProductInfo)"
            type="video/webm"
            loop
            autoplay
            muted
            @contextmenu.prevent.stop
            playsinline
          ></video>
        </template>
      </div>
      <div class="pd_payment">
        <div class="payment-card">
          <div class="payment-card-left">
            <div class="text">
              <div class="dot"/>결제를 진행해주세요 <div class="dot"/>결제를 진행해주세요 <div class="dot"/>결제를 진행해주세요 <div class="dot"/>결제를 진행해주세요 <div class="dot"/>결제를 진행해주세요<div class="dot"/>결제를 진행해주세요 <div class="dot"/>결제를 진행해주세요<div class="dot"/>결제를 진행해주세요
            </div>
          </div>
          <div class="payment-card-artist">
              <img
                v-if="paymentProductInfo.profileImage !== undefined"
                class="artist-img"
                :src="paymentProductInfo.profileImageUrl"
              />
              <img
                v-else
                class="artist-img"
                src="/img/avatars/profile-3space-logo.png"
              />
            <div class="text-wrap">
              <div class="top">
                <span class="name">
                <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                  {{ paymentProductInfo.artistName || paymentProductInfo.artistNameEn }}
                </template>
                <template v-else>
                  {{ paymentProductInfo.artistNameEn || paymentProductInfo.artistName }}
                </template>
              </span>
                <span class="nickname"> @{{ paymentProductInfo.artistNickName }}</span>
              </div>
              <div class="bottom">
                <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                  {{ paymentProductInfo.productName || paymentProductInfo.productNameEn }}
                </template>
                <template v-else>
                  {{ paymentProductInfo.productNameEn || paymentProductInfo.productName }}
                </template>
              </div>
            </div>
          </div>
          <div class="payment-card-info">
            <div class="info-wrap">
              <div class="type">{{ $t("payment.artworkNft") }}</div>
              <div class="value"># {{ paymentProductInfo.tokenId }}</div>
            </div>
            <hr/>
            <div class="info-wrap">
              <div class="type">{{ $t("payment.artworkPrice") }}</div>
              <div class="value">
                <div class="coin" v-if="orderPaymentInfo.nftType === 'ORDINALS'">
                  {{ paymentProductInfo.productPrice}}
                </div>
                <div class="fiat" v-else>
                  {{ paymentProductInfo.productPrice | currency }} KRW
                </div>
                <div class="coin" v-if="orderPaymentInfo.paymentType === 'SSX'">
                  <v-icon small class="text-black mx-2">mdi-zodiac-aquarius</v-icon>
                  {{ ssxCurrency.productPriceToken | currencyCoin(ssxCurrency.productPriceTokenCurrency) }}
                  {{ ssxCurrency.productPriceTokenCurrency }}
                </div>
                <template v-else>
                  <div class="coin" v-if="paymentProductInfo.productPriceCoin !== undefined && paymentProductInfo.productPriceCoin > 0">
                    <v-icon small class="text-black mx-2">mdi-zodiac-aquarius</v-icon>
                    {{ paymentProductInfo.productPriceCoin | currencyCoin(paymentProductInfo.productPriceCurrency) }}
                    {{ getCoinCurrencyByCurrency(paymentProductInfo.productPriceCurrency) }}
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="payment-card-check">
            <div class="info-wrap">
              <div class="type">{{ $t("payment.artworkTotal") }}</div>
              <div class="value">
                <div class="coin" v-if="orderPaymentInfo.nftType === 'ORDINALS'">
                  {{ paymentProductInfo.productPrice}}
                </div>
                <div class="fiat" v-else>
                  {{ paymentProductInfo.productPrice | currency }} KRW
                </div>
                <div class="coin" v-if="orderPaymentInfo.paymentType === 'SSX'">
                  <v-icon small class="text-black mx-2">mdi-zodiac-aquarius</v-icon>
                  {{ ssxCurrency.productPriceToken | currencyCoin(ssxCurrency.productPriceTokenCurrency) }}
                  {{ ssxCurrency.productPriceTokenCurrency }}
                </div>
                <template v-else>
                  <div class="coin" v-if="paymentProductInfo.productPriceCoin !== undefined && paymentProductInfo.productPriceCoin > 0">
                    <v-icon small class="text-black mx-2">mdi-zodiac-aquarius</v-icon>
                    {{ paymentProductInfo.productPriceCoin | currencyCoin(paymentProductInfo.productPriceCurrency) }}
                    {{ getCoinCurrencyByCurrency(paymentProductInfo.productPriceCurrency) }}
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="payment-card-bottom">
            <template v-if="paymentProductInfo.productPriceCoin !== undefined && paymentProductInfo.productPriceCoin > 0">
              bithumb, coinone. {{ paymentProductInfo.coinTickerTime }}
            </template>
          </div>
        </div>

        <div class="buyer-info">
          <div class="title">{{ $t("payment.buyerInfo") }}</div>
          <div class="text-wrap">
            <div class="text-container">
              <div class="type">
                {{ $t("payment.buyerName") }}
              </div>
              <div class="value">
                @{{ me.nickname }}
              </div>
            </div>
            <div class="text-container">
              <div class="type">
                {{ $t("payment.buyerEmail") }}
              </div>
              <div class="value">
                {{ me.email }}
              </div>
            </div>
            <div class="text-container">
              <div class="type">
                {{ $t("payment.buyerWalletAddr") }}
              </div>
              <div class="value">
                <template v-if="paymentProductInfo.nftType === 'ETHEREUM'">
                  <b-form-input readonly v-model="memberInfo.ethereumAddr" :placeholder="$t('validation.requiredWalletAddr')"></b-form-input>
                  <b-input-group-append v-if="isForSale">
                    <b-button size="sm" variant="primary" class="text-white font-sm" @click="showWalletPopupModal" v-if="memberInfo.snsType !== null && memberInfo.snsType !== 'WALLET' && memberInfo.ethereumAddr == null">
                      <CIcon name="cil-wallet" size="lg" />
                    </b-button>
                  </b-input-group-append>
                </template>
                <template v-else-if="paymentProductInfo.nftType === 'POLYGON'">
                  <b-form-input readonly v-model="memberInfo.polygonAddr" :placeholder="$t('validation.requiredWalletAddr')"></b-form-input>
                  <b-input-group-append v-if="isForSale">
                    <b-button size="sm" variant="primary" class="text-white font-sm" @click="showWalletPopupModal" v-if="memberInfo.snsType !== null && memberInfo.snsType !== 'WALLET' && memberInfo.polygonAddr == null">
                      <CIcon name="cil-wallet" size="lg" />
                    </b-button>
                  </b-input-group-append>
                </template>
                <template v-else-if="paymentProductInfo.nftType === 'KLAYTN' || paymentProductInfo.nftType === 'KLIP'">
                  <b-form-input readonly v-model="memberInfo.klaytnAddr" :placeholder="$t('validation.requiredWalletAddr')"></b-form-input>
                  <b-input-group-append v-if="isForSale">
                    <b-button size="sm" variant="primary" class="text-white font-sm" @click="showWalletPopupModal" v-if="memberInfo.snsType !== null && memberInfo.snsType !== 'WALLET' && memberInfo.klaytnAddr == null">
                      <CIcon name="cil-wallet" size="lg" />
                    </b-button>
                  </b-input-group-append>
                </template>
              </div>
            </div>
          </div>
        </div>

        <hr/>

        <div class="warning-info">
          <div class="text-wrap">
            <div class="text">{{ $t("payment.deliveryWarning.1") }}</div>
            <div class="text">{{ $t("payment.deliveryWarning.2") }}</div>
            <div class="text">{{ $t("payment.deliveryWarning.3") }}</div>
            <div class="text">{{ $t("payment.deliveryWarning.4") }}</div>
            <div class="text">{{ $t("payment.deliveryWarning.5") }}</div>
            <div class="text">{{ $t("payment.deliveryWarning.6") }}</div>
          </div>
        </div>

        <div class="pay-section">
          <div class="title">
            {{ $t("payment.payMethod") }}
          </div>
          <div class="pay-method">
            <b-form-radio-group
              id="paymentType"
              name="paymentType"
              v-model="orderPaymentInfo.paymentType"
              class="text-dark"
              :disabled="!isForSale"
              @change="onChangePaymentType"
            >
              <template v-if="paymentProductInfo.nftType === 'ETHEREUM'">
                <template v-for="(options, i) in paymentTypeEthereumOptions">
                  <b-form-radio :key="i" :value="options.value" :class="{'selected': orderPaymentInfo.paymentType === options.value}">{{options.text}}</b-form-radio>
                </template>
              </template>
              <template v-else-if="paymentProductInfo.nftType === 'POLYGON'">
                <template v-for="(options, i) in paymentTypePolygonOptions">
                  <b-form-radio :key="i" :value="options.value" :class="{'selected': orderPaymentInfo.paymentType === options.value}">{{options.text}}</b-form-radio>
                </template>
              </template>
              <template v-else-if="paymentProductInfo.nftType === 'ORDINALS'">
                <template v-for="(options, i) in paymentTypeOrdinalsOptions">
                  <b-form-radio :key="i" :value="options.value" :class="{'selected': orderPaymentInfo.paymentType === options.value}">{{options.text}}</b-form-radio>
                </template>
              </template>
              <template v-else>
                <template v-for="(options, i) in paymentTypeKlaytnOptions">
                  <b-form-radio :key="i" :value="options.value" :class="{'selected': orderPaymentInfo.paymentType === options.value}">{{options.text}}</b-form-radio>
                </template>
              </template>
            </b-form-radio-group>
          </div>
        </div>

        <div class="agreement">

        </div>


        <!-- 결제 수단 -->

        <div class="pd_payment_agreement">
          <div class="agree_msg text-left">
            <div class="d-flex" v-if="(orderPaymentInfo.paymentType === 'VBANK' || orderPaymentInfo.paymentType === 'CARD' || orderPaymentInfo.paymentType === 'TRANSFER')
              && (memberInfo.acceptTermsService === null || memberInfo.acceptTermsService === false)">
              <CInputCheckbox
                :label="$t('login.termsService')"
                :checked.sync="$v.form.acceptTermsService.$model"
                :isValid="checkIfValid('acceptTermsService')"
                :invalidFeedback="$t('validation.required')"
                custom
                class="mr-2"
                :disabled="!isForSale"
              />
              <a @click="onClickShowTerms('terms_of_service')" class="cursor-pointer"><CIcon name="cil-external-link" /></a>
            </div>
            <div class="d-flex" v-if="(orderPaymentInfo.paymentType === 'VBANK' || orderPaymentInfo.paymentType === 'CARD' || orderPaymentInfo.paymentType === 'TRANSFER')
              && (memberInfo.acceptPrivacyPolicy === null || memberInfo.acceptPrivacyPolicy === false)">
              <CInputCheckbox
                :label="$t('login.privacyPolicy')"
                :checked.sync="$v.form.acceptPrivacyPolicy.$model"
                :isValid="checkIfValid('acceptPrivacyPolicy')"
                :invalidFeedback="$t('validation.required')"
                custom
                class="mr-2"
                :disabled="!isForSale"
              />
              <a @click="onClickShowTerms('privacy_policy')" class="cursor-pointer"><CIcon name="cil-external-link" /></a>
            </div>
            <div class="d-flex">
              <CInputCheckbox
                :label="$t('payment.agreeTerms')"
                :checked.sync="$v.form.acceptTerms.$model"
                :isValid="checkIfValid('acceptTerms')"
                :invalidFeedback="$t('validation.required')"
                custom
                class="mr-2"
                :disabled="!isForSale"
              />
              <a @click="onClickShowTerms('terms_of_transaction')" class="cursor-pointer"><CIcon name="cil-external-link" /></a>
            </div>
            <div class="d-flex">
              <CInputCheckbox
                :label="$t('payment.agreePayment')"
                :checked.sync="$v.form.acceptConfirm.$model"
                :isValid="checkIfValid('acceptConfirm')"
                :invalidFeedback="$t('validation.required')"
                custom
                :disabled="!isForSale"
              />
            </div>
          </div>
          <b-button
            size="lg"
            :disabled="
              !form.acceptTermsService ||
              !form.acceptPrivacyPolicy ||
              !form.acceptConfirm ||
              !form.acceptTerms ||
              !isForSale
            "
            @click="onClickPayment"
          >
            {{ $t("button.payment") }}
          </b-button>
        </div>
      </div>
    </div>

    <!-- 결제, 로그인 메시지 팝업 -->
    <b-modal
      id="paymentLoginModal"
      ref="paymentLoginModal"
      hide-header
      hide-footer
      centered
      body-class="text-center my-4"
    >
      <b-img src="/img/threespace/logo/logo_100x100.png" width="70"></b-img>
      <p class="mt-3">
        {{ $t("product.requiredLogin") }}
      </p>
      <div class="w-100 text-center mt-5">
        <b-button
          variant="primary"
          class="px-5 py-2 text-white"
          squared
          @click="goLogin()"
        >
          {{ $t("button.confirm") }}
        </b-button>
      </div>
    </b-modal>

    <!-- 지갑 네트워크 메시지 팝업 -->
    <b-modal
      id="paymentWalletModal"
      ref="paymentWalletModal"
      hide-header
      hide-footer
      centered
      body-class="text-center my-4"
    >
      <b-img src="/img/threespace/logo/logo_100x100.png" width="70"></b-img>
      <p class="mt-3 text-dark" v-html="walletMessage"></p>
      <div class="w-100 text-center mt-5">
        <b-button
          variant="primary"
          size="lg"
          squared
          class="text-white px-5 py-2"
          @click="$refs.paymentWalletModal.hide()"
        >
          {{ $t("button.confirm") }}
        </b-button>
      </div>
    </b-modal>

    <!-- 서비스 이용동의, 가상계좌 결제>본인인증 메시지 팝업 -->
    <b-modal
      id="paymentMypageModal"
      ref="paymentMypageModal"
      hide-header
      hide-footer
      centered
      body-class="text-center text-black my-4"
    >
      <b-img src="/img/threespace/logo/logo_100x100.png" width="70"></b-img>
      <p class="mt-3" v-html="mypageMessage"></p>
      <div class="w-100 text-center mt-5">
        <b-button
          squared
          class="text-white px-4 py-2 mr-3"
          @click="$refs.paymentMypageModal.hide()"
        >
          {{ $t("button.cancel") }}
        </b-button>
        <b-button
          variant="primary"
          squared
          class="text-white px-4 py-2"
          @click="goMyPage"
        >
          {{ $t("product.gotoMyPage") }}
        </b-button>
      </div>
    </b-modal>

    <!-- 결제 팝업 -->
    <PaymentVBank1 :order-payment-info="orderPaymentInfo" ref="paymentVBank1" />
    <PaymentKlip1 :order-payment-info="orderPaymentInfo" ref="paymentKlip1" />
    <PaymentKaikas1
      :order-payment-info="orderPaymentInfo"
      :payment-product-info="paymentProductInfo"
      :member-info="memberInfo"
      ref="paymentKaikas1"
    />
    <PaymentNeopin1
      :order-payment-info="orderPaymentInfo"
      :payment-product-info="paymentProductInfo"
      :member-info="memberInfo"
      ref="paymentNeopin1"
    />
    <PaymentMetaMask1
      :order-payment-info="orderPaymentInfo"
      :payment-product-info="paymentProductInfo"
      :member-info="memberInfo"
      ref="paymentMetaMask1"
    />
    <PaymentWalletConnect1
      :order-payment-info="orderPaymentInfo"
      :payment-product-info="paymentProductInfo"
      :member-info="memberInfo"
      ref="paymentWalletConnect1"
    />
    <PaymentXverse1
      :order-payment-info="orderPaymentInfo"
      :payment-product-info="paymentProductInfo"
      :member-info="memberInfo"
      ref="paymentXverse1"
    />


    <!-- 지갑 연결 팝업 -->
    <WalletPopup ref="walletPopupModal" @wallet-info="getWalletInfo" />

    <!-- 간편결제 파라미터 -->
    <form name="payForm" id="payForm">
      <input type="hidden" id="GoodsName" name="GoodsName" />
      <input type="hidden" id="Amt" name="Amt" />
      <input type="hidden" id="MID" name="MID" />
      <input type="hidden" id="EdiDate" name="EdiDate" />
      <input type="hidden" id="Moid" name="Moid" />
      <input type="hidden" id="SignData" name="SignData" />
      <input type="hidden" id="BuyerName" name="BuyerName" />
      <input type="hidden" id="BuyerTel" name="BuyerTel" />
      <input type="hidden" id="PayMethod" name="PayMethod" />
      <input type="hidden" id="BuyerEmail" name="BuyerEmail" />
      <input type="hidden" id="CharSet" name="CharSet" />
      <!-- 모바일용 -->
      <input type="hidden" id="ReturnURL" name="ReturnURL" />
      <input type="hidden" id="ReqReserved" name="ReqReserved" />
    </form>

    <!-- 간편결제 처리중/처리완료 메시지 팝업 -->
    <b-modal
      id="completePaymentCardPC"
      ref="completePaymentCardPC"
      class="payment_complate_modal_wrap"
      hide-header
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
    >
      <CRow>
        <CCol class="col-12 py-2">
          <CLink @click="goHome">
            <img class="float-right" src="/img/threespace/icon/x-icon.png" />
          </CLink>
        </CCol>
      </CRow>

      <div class="payment_complete_modal">
        <div class="title">
          <p v-html="$t('payment.completePayment')"></p>
        </div>
        <div class="content">
          <b-table-simple borderless>
            <!-- 작품명 -->
            <tr>
              <th>{{ $t("payment.artwork.title") }}</th>
              <td>
                <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                  {{ paymentProductInfo.productName || paymentProductInfo.productNameEn }}
                </template>
                <template v-else>
                  {{ paymentProductInfo.productNameEn || paymentProductInfo.productName }}
                </template>
              </td>
            </tr>
            <!-- 소유주 -->
            <tr>
              <th>{{ $t("payment.easypay.ownership") }}</th>
              <td>
                <template v-if="!submitted">
                  <p v-html="$t('payment.easypay.messageTransfer')"></p>
                </template>
                <template v-else>
                  <a
                    v-if="paymentResultInfo.klaytnScopeUrl !== ''"
                    :href="paymentResultInfo.klaytnScopeUrl"
                    target="_blank"
                  >
                    <template v-if="paymentProductInfo.nftType === 'ETHEREUM'">
                      {{ $t("payment.easypay.etherscan") }}
                    </template>
                    <template v-else>
                      {{ $t("payment.easypay.klaytnScope") }}
                    </template>
                  </a>
                  <p v-else>
                    <template v-if="paymentProductInfo.nftType === 'ETHEREUM'">
                      {{ $t("payment.easypay.etherscan") }}
                    </template>
                    <template v-else>
                      {{ $t("payment.easypay.klaytnScope") }}
                    </template>
                  </p>
                </template>
              </td>
            </tr>
            <!-- 가격 -->
            <tr>
              <th>{{ $t("payment.artwork.price") }}</th>
              <td>
                {{ paymentProductInfo.productPrice | currency }} KRW
              </td>
            </tr>
            <!-- 주문일 -->
            <tr>
              <th>{{ $t("payment.easypay.orderDate") }}</th>
              <td>
                {{ paymentResultInfo.createdAt }}
              </td>

            </tr>
          </b-table-simple>
        </div>
        <div class="btn_box">
          <b-button
            class="goCollection"
            @click="goMyPage"
          >
            {{ $t("payment.goto.myPage") }}
          </b-button>
          <b-button
            class="goArtpool"
            @click="goArtpool"
          >
            {{ $t("payment.goArtPool") }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <PaymentTossTransfer1 ref="tossTransfer" @complete-toss-transfer="completeTossTransfer" />
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const galleryHelper = createNamespacedHelpers("gallery");
const authHelper = createNamespacedHelpers("auth");
const profileHelper = createNamespacedHelpers("profile");
const paymentHelper = createNamespacedHelpers("payment");

import WalletPopup from "@/views/threespace/WalletPopup";
import PaymentVBank1 from "./payment/PaymentVBank1";
import PaymentKlip1 from "./payment/PaymentKlip1";
import PaymentKaikas1 from "./payment/PaymentKaikas1";
import PaymentNeopin1 from "./payment/PaymentNeopin1";
import PaymentMetaMask1 from './payment/PaymentMetaMask1.vue';
import PaymentWalletConnect1 from './payment/PaymentWalletConnect1.vue';
import PaymentTossTransfer1 from "./payment/PaymentTossTransfer1";
import PaymentXverse1 from './payment/PaymentXverse1.vue';
import CoinUtils from "../../mixins/CoinUtils";

// NICEPAY 결제
let self;
const nicepaySubmit = () => {
  self.callbackNicepaySubmit();
}
const nicepayClose = () => {
  self.callbackNicepayClose();
}

export default {
  name: "Payment",
  components: {
    WalletPopup,
    PaymentVBank1,
    PaymentKlip1,
    PaymentKaikas1,
    PaymentMetaMask1,
    PaymentWalletConnect1,
    PaymentTossTransfer1,
    PaymentNeopin1,
    PaymentXverse1
  },
  props: {
    idxProduct: {
      type: String,
      default: "0"
    }
  },
  data: function() {
    return {
      isForSale: false,
      form: {
        acceptTerms: false,
        acceptConfirm: false,
        acceptTermsService: false,
        acceptPrivacyPolicy: false
      },
      orderPaymentInfo: {
        idxProduct: this.idxProduct,
        idxMember: null,
        idxOrderPayment: null,
        productName: null,
        productNameEn: null,
        productPrice: null,
        productPriceCoin: null,
        paymentType: null
      },
      memberInfo: {
        idx: null,
        snsType: null,
        klaytnAddr: null,
        ethereumAddr: null,
        polygonAddr: null,
        btcAddr: null,
        acceptTermsService: null,
        acceptPrivacyPolicy: null
        //walletAddr: null,
        //walletNetwork: null
      },
      paymentTypeKlaytnOptions: [
        // TODO NICEPAY
        // { value: "CARD", text: this.$t("payment.payMethodCard"), disabled: true },
        // { value: "VBANK", text: this.$t("payment.payMethodVbank"), disabled: true },
        { value: "TRANSFER", text: this.$t("payment.payMethodTransfer"), disabled: true },
        { value: "KLAY", text: this.$t("payment.payMethodKlay"), disabled: true },
        { value: "SSX", text: this.$t("payment.payMethodSSX"), disabled: true }
      ],
      paymentTypeEthereumOptions: [
        // TODO NICEPAY
        // { value: "CARD", text: this.$t("payment.payMethodCard"), disabled: true },
        // { value: "VBANK", text: this.$t("payment.payMethodVbank"), disabled: true },
        { value: "TRANSFER", text: this.$t("payment.payMethodTransfer"), disabled: true },
        { value: "ETH", text: this.$t("payment.payMethodEth"), disabled: true },
      ],
      paymentTypePolygonOptions: [
        { value: "TRANSFER", text: this.$t("payment.payMethodTransfer"), disabled: true },
        { value: "MATIC", text: this.$t("payment.payMethodMatic"), disabled: true },
      ],
      paymentTypeOrdinalsOptions: [
        { value: "BTC", text: this.$t("payment.payMethodBtc"), disabled: true },
      ],
      mypageMessage: "",
      walletMessage: "",
      walletAddress: {
        klaytn: null,
        ethereum: null,
        polygon: null,
        bitcoin: null,
      },
      submitted: false,
      cardPaymentInfo: {},
      paymentResultInfo: {},
    };
  },
  computed: {
    ...authHelper.mapState(["me"]),
    ...galleryHelper.mapState(["paymentProductInfo"]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
    isValid() {
      return !this.$v.form.$invalid;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
    getProductNetwork() {
      const nftType = this.paymentProductInfo.nftType;
      let network = null;

      if (nftType === "KLAYTN" || nftType === "KLIP") {
        network = "Klaytn";
      } else if (nftType === "ETHEREUM") {
        network = "Ethereum";
      } else if (nftType === "POLYGON") {
        network = "Polygon";
      } else if (nftType === "ORDINALS") {
        network = "Bitcoin";
      }
      return network;
    },
    ssxCurrency() {
      let ssxCurrency = {
        productPriceToken: "",
        productPriceTokenCurrency: "",
      };
      if (this.paymentProductInfo.productPriceTokenInfos.findIndex(el => el.productPriceTokenCurrency === "SSX") !== -1) {
        ssxCurrency = this.paymentProductInfo.productPriceTokenInfos.find(el => el.productPriceTokenCurrency === "SSX");
      }
      return ssxCurrency;
    }
  },
  mixins: [validationMixin, CoinUtils],
  validations() {
    if ((this.orderPaymentInfo.paymentType === "VBANK"
        || this.orderPaymentInfo.paymentType === "CARD"
        || this.orderPaymentInfo.paymentType === "TRANSFER")
        && (this.memberInfo.acceptTermsService === null
          || this.memberInfo.acceptTermsService === false
          || this.memberInfo.acceptPrivacyPolicy === null
          || this.memberInfo.acceptPrivacyPolicy === false)) {
      return {
        form: {
          acceptTerms: { required, mustAccept: val => val },
          acceptConfirm: { required, mustAccept: val => val },
          acceptTermsService: { required, mustAccept: val => val },
          acceptPrivacyPolicy: { required, mustAccept: val => val }
        }
      };
    } else {
      return {
        form: {
          acceptTerms: { required, mustAccept: val => val },
          acceptConfirm: { required, mustAccept: val => val }
        }
      };
    }
  },
  mounted() {
    this.requestGalleryProductItem();
    // NICEPAY 결제
    window.nicepaySubmit = nicepaySubmit;
    window.nicepayClose = nicepayClose;
    self = this;
    const nicepayScript = document.createElement("script");
    nicepayScript.setAttribute("src", "https://web.nicepay.co.kr/v3/webstd/js/nicepay-3.0.js");
    document.head.appendChild(nicepayScript);
  },
  methods: {
    ...galleryHelper.mapActions(["getPaymentProduct"]),
    ...authHelper.mapActions(["verifyTokenCallback"]),
    ...profileHelper.mapActions(["updateProfile"]),
    ...paymentHelper.mapActions(
      [
        "getPaymentKlay",
        "getPaymentToken",
        "getPaymentEth",
        "getPaymentMatic",
        "getPaymentBtc",
        "getPaymentCard",
        "updatePaymentCardPC",
        "transferNFT"
      ]
    ),
    requestGalleryProductItem() {
      if (this.idxProduct && this.idxProduct !== "0") {
        this.getPaymentProduct(this.idxProduct).then(() => {
          // 결제정보
          this.orderPaymentInfo.productName = this.paymentProductInfo.productName;
          this.orderPaymentInfo.productNameEn = this.paymentProductInfo.productNameEn;
          this.orderPaymentInfo.productPrice = this.paymentProductInfo.productPrice;
          if (this.paymentProductInfo.productPriceCoin !== undefined && this.paymentProductInfo.productPriceCoin > 0) {
            this.orderPaymentInfo.productPriceCoin = this.paymentProductInfo.productPriceCoin;
          }

          // 프로필 업데이트용
          this.memberInfo.idx = this.me.idx;
          this.memberInfo.snsType = this.me.snsType;
          this.memberInfo.klaytnAddr = this.me.klaytnAddr || null;
          this.memberInfo.ethereumAddr = this.me.ethereumAddr || null;
          this.memberInfo.polygonAddr = this.me.polygonAddr || null;
          this.memberInfo.btcAddr = this.me.btcAddr || null;
          this.memberInfo.acceptTermsService = this.me.acceptTermsService || null;
          this.memberInfo.acceptPrivacyPolicy = this.me.acceptPrivacyPolicy || null;
          this.form.acceptTermsService = this.me.acceptTermsService || false;
          this.form.acceptPrivacyPolicy = this.me.acceptPrivacyPolicy || false;
          //this.memberInfo.walletAddr = this.me.walletAddr;
          //this.memberInfo.walletNetwork = this.me.walletNetwork;

          // 주소 변경 > 중복 체크후 복원용
          this.walletAddress.klaytn = this.memberInfo.klaytnAddr || null;
          this.walletAddress.ethereum = this.memberInfo.ethereumAddr || null;
          this.walletAddress.polygon = this.memberInfo.polygonAddr || null;
          this.walletAddress.bitcoin = this.memberInfo.btcAddr || null;

          // 결제수단
          const paytypes = this.paymentProductInfo.paytypes;
          if (paytypes != null && paytypes.length > 0) {
            let selected = false;
            if (this.paymentProductInfo.nftType === "ETHEREUM") {
              this.paymentTypeEthereumOptions.forEach(item => {
                if (paytypes.includes(item.value)) {
                  if (item.value === 'ETH') {
                    if (this.paymentProductInfo.productPriceCoin !== undefined && this.paymentProductInfo.productPriceCoin > 0) {
                      item.disabled = false;
                    }
                  } else {
                    item.disabled = false;
                  }
                  if (!selected && !item.disabled) {
                    this.orderPaymentInfo.paymentType = item.value; // 디폴트 선택된 항목
                    selected = true;
                  }
                }
              });
            } else if (this.paymentProductInfo.nftType === 'KLAYTN' || this.paymentProductInfo.nftType === 'KLIP') {
              this.paymentTypeKlaytnOptions.forEach(item => {
                if (paytypes.includes(item.value)) {
                  if (item.value === 'KLAY') {
                    if (this.paymentProductInfo.productPriceCoin !== undefined && this.paymentProductInfo.productPriceCoin > 0) {
                      item.disabled = false;
                    }
                  } else {
                    item.disabled = false;
                  }
                  if (!selected && !item.disabled) {
                    this.orderPaymentInfo.paymentType = item.value; // 디폴트 선택된 항목
                    selected = true;
                  }
                }
              });
            } else if (this.paymentProductInfo.nftType === 'POLYGON') {
              this.paymentTypePolygonOptions.forEach(item => {
                if (paytypes.includes(item.value)) {
                  if (item.value === 'MATIC') {
                    if (this.paymentProductInfo.productPriceCoin !== undefined && this.paymentProductInfo.productPriceCoin > 0) {
                      item.disabled = false;
                    }
                  } else {
                    item.disabled = false;
                  }
                  if (!selected && !item.disabled) {
                    this.orderPaymentInfo.paymentType = item.value; // 디폴트 선택된 항목
                    selected = true;
                  }
                }
              });
            } else if (this.paymentProductInfo.nftType === 'ORDINALS') {
              this.paymentTypeOrdinalsOptions.forEach(item => {
                if(paytypes.includes(item.value)) {
                  if(item.value === 'BTC') {
                    if (this.paymentProductInfo.productPriceCoin !== undefined && this.paymentProductInfo.productPriceCoin > 0) {
                      item.disabled = false;
                    }
                  } else {
                    item.disabled = false;
                  }
                }
              })
            }
            if (
              this.orderPaymentInfo.paymentType !== undefined &&
              (this.orderPaymentInfo.paymentType === "KLAY" ||
                this.orderPaymentInfo.paymentType === "ETH" ||
                this.orderPaymentInfo.paymentType === "MATIC" ||
                this.orderPaymentInfo.paymentType === "SSX" ||
                this.orderPaymentInfo.paymentType === "BTC")
            ) {
              this.form.acceptTermsService = true;
              this.form.acceptPrivacyPolicy = true;
            }
          }
          // 작품상태 & 로그인 상태
          if (this.paymentProductInfo.status === 'FORSALE' && this.me.idx !== undefined) {
            this.isForSale = true;
          }
        });

        // TOSS transfer redirect
        const tossTransferStatus = this.$route.query.tossTransferStatus;
        if (tossTransferStatus) {
          const query = this.$route.query;
          this.$refs["tossTransfer"].confirm(query);
        }
      } else {
        // 디폴트
        this.orderPaymentInfo.paymentType = "TRANSFER";
      }
    },
    isProductFileTypeImage(_productFileType) {
      if (
        _productFileType !== undefined &&
        _productFileType.startsWith("image")
      ) {
        return true;
      } else {
        return false;
      }
    },
    replaceDisplayPosterUrl(item) {
      if (
        item.displayPoster !== undefined &&
        item.displayPoster !== null &&
        item.displayPoster !== "" &&
        item.displayPoster === "THREESPACE"
      ) {
        return item.displayPosterUrlThreespace; // 3space S3 URL
      } else {
        if (item.displayPosterUrl !== undefined && item.displayPosterUrl.startsWith("https://ipfs.io/")) {
          return item.displayPosterUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
        }
        return item.displayPosterUrl;
      }
    },
    replaceDisplayDetailUrl(item) {
      if (
        item.displayDetailFile !== undefined &&
        item.displayDetailFile !== null &&
        item.displayDetailFile !== "" &&
        item.displayDetailFile === "THREESPACE"
      ) {
        return item.displayDetailUrlThreespace; // 3space S3 URL
      } else {
        if (item.displayDetailUrl !== undefined && item.displayDetailUrl.startsWith("https://ipfs.io/")) {
          return item.displayDetailUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
        }
        return item.displayDetailUrl;
      }
    },
    showWalletPopupModal() {
      this.$refs["walletPopupModal"].showChangeWallet(this.getProductNetwork);
    },
    getWalletInfo(data) {
      if (process.env.NODE_ENV !== "production") {
        this.$log.log("wallet data: ", data);
      }
      // 이더리움
      if (data.network === "Ethereum") {
        if (process.env.NODE_ENV === "production" && data.chainId.toString() !== "1") {
          alert("이더리움 메인넷 지갑주소를 선택해주세요.");
          return;
        }
        let needUpdate = false;
        if (this.memberInfo.ethereumAddr !== undefined
          && this.memberInfo.ethereumAddr !== null
          && this.memberInfo.ethereumAddr === data.addr) {
          alert("이미 등록된 지갑주소입니다. 지갑주소를 확인해주세요.");
          this.memberInfo.ethereumAddr = this.walletAddress.ethereumAddr;
          return;
        }
        if (this.memberInfo.ethereumAddr !== undefined
          && this.memberInfo.ethereumAddr !== null
          && this.memberInfo.ethereumAddr !== ''
          && this.memberInfo.ethereumAddr !== data.addr) {
          if (confirm("등록되지 않은 지갑주소입니다.\n신규 지갑주소를 등록할 경우, 기존 등록된 지갑의 컬렉션은 조회가 안될 수 있습니다.\n\n등록 하시겠습니까?")) {
            needUpdate = true;
          } else {
            return;
          }
        }
        if (this.memberInfo.ethereumAddr === undefined
          || this.memberInfo.ethereumAddr === null
          || this.memberInfo.ethereumAddr === ""
          || needUpdate) {
          this.memberInfo.ethereumAddr = data.addr;
          this.$forceUpdate();
          if (this.memberInfo.ethereumAddr != null && this.memberInfo.ethereumAddr != '') {
            this.updateProfile(this.memberInfo)
              .then(() => {
                this.$log.log("지갑주소 등록이 완료되었습니다.\n\n마이페이지에서 프로필 정보를 확인해주세요.");
              })
              .catch(error => {
                if (error.data.reason === 'BAD_REQUEST' && error.data.resource === 'EXISTS_ETHEREUM_ADDR') {
                  alert("이미 등록된 지갑주소입니다. 지갑주소를 확인해주세요.");
                  this.memberInfo.ethereumAddr = this.walletAddress.ethereumAddr;
                }
              });
          }
        }
      // 클레이튼
      } else if (data.network === "Klaytn") {
        let needUpdate = false;
        if (this.memberInfo.klaytnAddr !== undefined
          && this.memberInfo.klaytnAddr !== null
          && this.memberInfo.klaytnAddr === data.addr) {
          alert("이미 등록된 지갑주소입니다. 지갑주소를 확인해주세요.");
          this.memberInfo.klaytnAddr = this.walletAddress.klaytnAddr;
          return;
        }
        if (this.memberInfo.klaytnAddr !== undefined
          && this.memberInfo.klaytnAddr !== null
          && this.memberInfo.klaytnAddr !== ''
          && this.memberInfo.klaytnAddr !== data.addr) {
          if (confirm("등록되지 않은 지갑주소입니다.\n신규 지갑주소를 등록할 경우, 기존 등록된 지갑의 컬렉션은 조회가 안될 수 있습니다.\n\n등록 하시겠습니까?")) {
            needUpdate = true;
          } else {
            return;
          }
        }
        if (this.memberInfo.klaytnAddr === undefined
          || this.memberInfo.klaytnAddr === null
          || this.memberInfo.klaytnAddr === ""
          || needUpdate) {
          this.memberInfo.klaytnAddr = data.addr;
          this.$forceUpdate();
          if (this.memberInfo.klaytnAddr != null && this.memberInfo.klaytnAddr != '') {
            this.updateProfile(this.memberInfo)
              .then(() => {
                this.$log.log("지갑주소 등록이 완료되었습니다.\n\n마이페이지에서 프로필 정보를 확인해주세요.");
              })
              .catch(error => {
                if (error.data.reason === 'BAD_REQUEST' && error.data.resource === 'EXISTS_KLAYTN_ADDR') {
                  alert("이미 등록된 지갑주소입니다. 지갑주소를 확인해주세요.");
                  this.memberInfo.klaytnAddr = this.walletAddress.klaytnAddr;
                }
              });
          }
        }
      }
    },
    onChangePaymentType(type) {
      if (type !== undefined
        && (type === "ETH" || type === "MATIC" || type === "KLAY" || type === "SSX" || type === "BTC")) {
        this.form.acceptTermsService = true;
        this.form.acceptPrivacyPolicy = true;
      } else {
        this.form.acceptTermsService = this.memberInfo.acceptTermsService;
        this.form.acceptPrivacyPolicy = this.memberInfo.acceptPrivacyPolicy;
      }
    },
    onClickPayment() {
      //this.$log.info("[onClickPayment] >> ", this.orderPaymentInfo);
      this.$v.$touch();
      if (this.isValid) {
        this.verifyTokenCallback()
          .then(() => {
            if (
              this.me === undefined ||
              this.me === null ||
              this.me.idx === undefined ||
              this.me.idx === null ||
              this.me.idx === ""
            ) {
              // 로그인
              this.$refs["paymentLoginModal"].show();
            } else {
              this.mypageMessage = "";
              this.walletMessage = "";
              let isOk = true;
              let changeNetwork = false;

              // 결제시 지갑주소 필수
              if (this.getProductNetwork === undefined
                || this.getProductNetwork === null
                || this.getProductNetwork === "") {
                isOk = false;
              } else {
                if (this.getProductNetwork === "Klaytn"
                  && (this.memberInfo.klaytnAddr === undefined
                    || this.memberInfo.klaytnAddr === null
                    || this.memberInfo.klaytnAddr === "")) {
                  isOk = false;
                  if (this.me.snsType === "WALLET") {
                    changeNetwork = true;
                  }
                }
                if (this.getProductNetwork === "Ethereum"
                  && (this.memberInfo.ethereumAddr === undefined
                    || this.memberInfo.ethereumAddr === null
                    || this.memberInfo.ethereumAddr === "")) {
                  isOk = false;
                  if (this.me.snsType === "WALLET") {
                    changeNetwork = true;
                  }
                }
                if (this.getProductNetwork === "Polygon"
                  && (this.memberInfo.polygonAddr === undefined
                    || this.memberInfo.polygonAddr === null
                    || this.memberInfo.polygonAddr === "")) {
                  isOk = false;
                  if (this.me.snsType === "WALLET") {
                    changeNetwork = true;
                  }
                }
                if (this.getProductNetwork === "Bitcoin"
                  && (this.memberInfo.btcAddr === undefined
                  || this.memberInfo.btcAddr === null
                  || this.memberInfo.btcAddr === "")) {
                  isOk = false;
                  if (this.me.snsType === "WALLET") {
                    changeNetwork = true;
                  }
                }
              }
              // 간편결제 또는 가상계좌 결제시 본인인증 필수
              if (this.orderPaymentInfo.paymentType === undefined
                || this.orderPaymentInfo.paymentType === null
                || this.orderPaymentInfo.paymentType === "") {
                isOk = false;
              } else {
                if ((this.orderPaymentInfo.paymentType === "VBANK"
                    || this.orderPaymentInfo.paymentType === "CARD"
                    || this.orderPaymentInfo.paymentType === "TRANSFER")
                    && (this.me.identityVerify === undefined
                      || this.me.identityVerify === null
                      || this.me.identityVerify === false)) {
                  isOk = false;
                }
              }
              // WALLET 가입회원 > NFT와 동일 네트워크로 로그인 필요
              if (changeNetwork) {
                this.walletMessage = this.$t("payment.requiredWalletMainnet").replaceAll("{network}", this.getProductNetwork);
                this.$refs["paymentWalletModal"].show();
                return;
              }
              if (!isOk) {
                // 마이페이지 이동
                this.mypageMessage = this.$t("payment.requiredTotalMsg").replaceAll("{network}", this.getProductNetwork);
                this.$refs["paymentMypageModal"].show();
              } else {
                this.orderPaymentInfo.idxMember = this.me.idx;
                if (this.orderPaymentInfo.paymentType === "VBANK"
                  || this.orderPaymentInfo.paymentType === "CARD"
                  || this.orderPaymentInfo.paymentType === "TRANSFER") {
                  // 서비스 이용약관 동의 저장(최초 1회)
                  if (this.memberInfo.acceptTermsService === null
                    || this.memberInfo.acceptTermsService === false
                    || this.memberInfo.acceptPrivacyPolicy === null
                    || this.memberInfo.acceptPrivacyPolicy === false) {
                    const acceptInfo = {
                      idx: this.memberInfo.idx,
                      acceptTermsService: this.form.acceptTermsService,
                      acceptPrivacyPolicy: this.form.acceptPrivacyPolicy
                    };
                    this.updateProfile(acceptInfo)
                      .then(() => {
                        this.$log.log("서비스 이용약관에 동의하였습니다.");
                        // 나이스페이 가상계좌/간편결제 or 토스 계좌이체
                        this.paymentNicepayVbankCardOrTossTransfer();
                      })
                      .catch(error => {
                        this.$log.error(error);
                        alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
                      });
                  } else {
                    // 나이스페이 가상계좌/간편결제 or 토스 계좌이체
                    this.paymentNicepayVbankCardOrTossTransfer();
                  }
                } else if (this.orderPaymentInfo.paymentType === "KLAY") {
                  // 작품 상태 체크 및 코인시세 정보 조회
                  this.getPaymentKlay(this.orderPaymentInfo.idxProduct)
                    .then(data => {
                      if (Object.keys(data).length !== 0 && JSON.stringify(data) !== JSON.stringify({})) {
                        // 결제 팝업 표시전 판매가(코인) 업데이트
                        this.paymentProductInfo.productPriceCoin = data.amountCoin;
                        this.paymentProductInfo.productPriceCurrency = data.currency;
                        this.orderPaymentInfo.idxOrderPayment = data.idx;
                        this.orderPaymentInfo.productPriceCoin = data.amountCoin;

                        const walletInfo = JSON.parse(localStorage.getItem("walletInfo"));
                        const walletProvider = this.me.klaytnProvider || walletInfo.walletProvider ;

                        if(walletProvider === "Kaikas") {
                          this.$refs["paymentKaikas1"].show(this.orderPaymentInfo.paymentType);
                        } else if (walletProvider === "Neopin") {
                          this.$refs["paymentNeopin1"].show(this.orderPaymentInfo.paymentType);
                        } else {
                          this.$refs["paymentKlip1"].show(this.orderPaymentInfo.paymentType);
                        }
                      } else {
                        alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
                      }
                    })
                    .catch(error => {
                      this.$log.error(error);
                      if (error.data.error === "NOT_FOUND" && error.data.resource === "PRODUCT_FORSALE") {
                        alert("구매 가능한 작품이 존재하지 않습니다.");
                      } else if (error.data.error === "NOT_FOUND" && error.data.resource === "ENTITY") {
                        alert("사용자(회원) 정보가 존재하지 않습니다.");
                      } else {
                        alert("주문결제 오류가 발생하였습니다. 관리자에게 문의하세요.");
                      }
                    });
                } else if (this.orderPaymentInfo.paymentType === "SSX") {
                  // 작품 상태 체크 및 Token시세 정보 조회
                  let params = {
                    idxProduct: this.orderPaymentInfo.idxProduct,
                    currency: this.orderPaymentInfo.paymentType,
                  }
                  this.getPaymentToken(params)
                    .then(data => {
                      if (Object.keys(data).length !== 0 && JSON.stringify(data) !== JSON.stringify({})) {
                        // 결제 팝업 표시전 판매가(SSX Token) 업데이트
                        let currentCurrency =
                          this.paymentProductInfo.productPriceTokenInfos.find(
                            (el) =>
                              el.productPriceTokenCurrency ===
                              this.orderPaymentInfo.paymentType
                          );
                        currentCurrency.productPriceToken = data.amountCoin;
                        currentCurrency.productPriceTokenCurrency = data.currency;
                        this.orderPaymentInfo.idxOrderPayment = data.idx;
                        this.orderPaymentInfo.productPriceCoin = data.amountCoin;
                        this.$refs["paymentKlip1"].show(this.orderPaymentInfo.paymentType);
                      } else {
                        alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
                      }
                    })
                    .catch(error => {
                      this.$log.error(error);
                      if (error.data.error === "NOT_FOUND" && error.data.resource === "PRODUCT_FORSALE") {
                        alert("구매 가능한 작품이 존재하지 않습니다.");
                      } else if (error.data.error === "NOT_FOUND" && error.data.resource === "ENTITY") {
                        alert("사용자(회원) 정보가 존재하지 않습니다.");
                      } else {
                        alert("주문결제 오류가 발생하였습니다. 관리자에게 문의하세요.");
                      }
                    });
                } else if (this.orderPaymentInfo.paymentType === "ETH") {
                  // 작품 상태 체크 및 코인시세 정보 조회
                  this.getPaymentEth(this.orderPaymentInfo.idxProduct)
                    .then(data => {
                      if (Object.keys(data).length !== 0 && JSON.stringify(data) !== JSON.stringify({})) {
                        // 결제 팝업 표시전 판매가(코인) 업데이트
                        this.paymentProductInfo.productPriceCoin = data.amountCoin;
                        this.paymentProductInfo.productPriceCurrency = this.getCoinCurrencyByCurrency(data.currency);
                        this.orderPaymentInfo.idxOrderPayment = data.idx;
                        this.orderPaymentInfo.productPriceCoin = data.amountCoin;

                        const walletInfo = JSON.parse(localStorage.getItem("walletInfo"));
                        const walletProvider = this.me.ethereumProvider || walletInfo.walletProvider ;

                        // this.$log.info("walletProvider", walletProvider)

                        if(walletProvider === "MetaMask") {
                          this.$refs["paymentMetaMask1"].show(this.orderPaymentInfo.paymentType);
                        } else {
                          this.$refs["paymentWalletConnect1"].show(this.orderPaymentInfo.paymentType);
                        }
                      } else {
                        alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
                      }
                    })
                    .catch(error => {
                      this.$log.error(error);
                      if (error.data.error === "NOT_FOUND" && error.data.resource === "PRODUCT_FORSALE") {
                        alert("구매 가능한 작품이 존재하지 않습니다.");
                      } else if (error.data.error === "NOT_FOUND" && error.data.resource === "ENTITY") {
                        alert("사용자(회원) 정보가 존재하지 않습니다.");
                      } else {
                        alert("주문결제 오류가 발생하였습니다. 관리자에게 문의하세요.");
                      }
                    });
                } else if (this.orderPaymentInfo.paymentType === "BTC") {
                  // 작품 상태 체크 및 코인시세 정보 조회
                  this.getPaymentBtc(this.orderPaymentInfo.idxProduct)
                    .then(data => {
                      console.log(data)
                      if (Object.keys(data).length !== 0 && JSON.stringify(data) !== JSON.stringify({})) {
                        // 결제 팝업 표시전 판매가(코인) 업데이트
                        this.paymentProductInfo.productPriceCoin = data.amountCoin;
                        this.paymentProductInfo.productPriceCurrency = this.getCoinCurrencyByCurrency(data.currency);
                        this.orderPaymentInfo.idxOrderPayment = data.idx;
                        this.orderPaymentInfo.productPriceCoin = data.amountCoin;

                        const walletInfo = JSON.parse(localStorage.getItem("walletInfo"));
                        const walletProvider = this.me.polygonProvider || walletInfo.walletProvider ;

                        // this.$log.info("walletProvider", walletProvider)

                        if(walletProvider === "Xverse") {
                          this.$refs["paymentXverse1"].show(this.orderPaymentInfo.paymentType);
                        } else {
                          alert("현재 Xverse Wallet만 지원하고 있습니다.")
                        }
                      } else {
                        alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
                      }

                    })
                    .catch(error => {
                      this.$log.error(error);
                      if (error.data.error === "NOT_FOUND" && error.data.resource === "PRODUCT_FORSALE") {
                        alert("구매 가능한 작품이 존재하지 않습니다.");
                      } else if (error.data.error === "NOT_FOUND" && error.data.resource === "ENTITY") {
                        alert("사용자(회원) 정보가 존재하지 않습니다.");
                      } else {
                        alert("주문결제 오류가 발생하였습니다. 관리자에게 문의하세요.");
                      }
                    });
                } else {
                  alert("결제 가능한 결제수단이 존재하지 않습니다.");
                }
              }
            }
          })
          .catch(error => {
            this.$log.error(error);
            if (error === 'REQUIRED_LOGIN') {
              // 로그인
              this.$refs["paymentLoginModal"].show();
            } else {
              alert("예기하지 않은 오류가 발생하였습니다. 관리자에게 문의하세요.");
            }
          });
      }
    },
    paymentNicepayVbankCardOrTossTransfer() {
      if (this.orderPaymentInfo.paymentType === "VBANK") {
        // 가상계좌 결제팝업
        this.$refs["paymentVBank1"].show();
      } else if (this.orderPaymentInfo.paymentType === "CARD") {
        // 간편결제 팝업 > NICEPAY
        this.getPaymentCard(this.orderPaymentInfo.idxProduct)
          .then(data => {
            if (Object.keys(data).length !== 0 && JSON.stringify(data) !== JSON.stringify({})) {
              this.cardPaymentInfo = {};
              this.cardPaymentInfo.idxOrderPayment = data.idx;
              this.cardPaymentInfo.idxProduct = data.idxProduct;
              // NICEPAY Start
              this.nicepayStart(data);
            } else {
              alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
            }
          })
          .catch(error => {
            this.$log.error(error);
            if (error.data.error === "NOT_FOUND" && error.data.resource === "PRODUCT_FORSALE") {
              alert("구매 가능한 작품이 존재하지 않습니다.");
            } else if (error.data.error === "NOT_FOUND" && error.data.resource === "ENTITY") {
              alert("사용자(회원) 정보가 존재하지 않습니다.");
            } else {
              alert("주문결제 오류가 발생하였습니다. 관리자에게 문의하세요.");
            }
          });
      } else if (this.orderPaymentInfo.paymentType === "TRANSFER") {
        // TOSS 계좌이체 결제팝업
        const params = {
          idxProduct: this.idxProduct
        }
        this.$refs["tossTransfer"].request(params);
      } else {
        alert("현재 지원하지 않는 결제수단입니다. 확인 후 다시 시도해주세요.");
      }
    },
    completeTossTransfer(status) {
      // console.log(status);
      this.$router.replace(this.$route.path);
    },
    // NICEPAY 간편결제 >>>>
    nicepayStart(data) {
      //this.$log.log(data);
      // 결제 요청 파라미터
      let payForm = document.payForm;
      payForm.GoodsName.value = this.activeLanguage !== undefined && this.activeLanguage === "ko" ? this.paymentProductInfo.productName : this.paymentProductInfo.productNameEn;
      payForm.Amt.value = data.amt;
      payForm.MID.value = data.mid;
      payForm.EdiDate.value = data.ediDate;
      payForm.Moid.value = data.moid;
      payForm.SignData.value = data.signData;
      payForm.BuyerName.value = data.buyerName;
      payForm.BuyerTel.value = data.buyerTel;
      payForm.PayMethod.value = "CARD";
      payForm.BuyerEmail.value = data.buyerEmail;
      payForm.CharSet.value = "utf-8";

      // NICEPAY 결제창 최초 요청시 실행
      if (this.checkPlatform(window.navigator.userAgent) == "mobile") { //모바일 결제창 진입
        payForm.ReqReserved.value = data.reqReserved;
        payForm.ReturnURL.value = process.env.VUE_APP_BACKEND_API_URL + "/threespace/v1/nicepay/card/mobile/callback";
        document.payForm.action = "https://web.nicepay.co.kr/v3/v3Payment.jsp";
        document.payForm.submit();
      } else { //PC 결제창 진입
        //this.$log.log(payForm);
        goPay(document.payForm);
      }
    },
    callbackNicepaySubmit() {
      //this.$log.log("callbackNicepaySubmit()", document.payForm);
      this.cardPaymentInfo.authResultCode = document.querySelector("input[name=AuthResultCode]").value;
      this.cardPaymentInfo.authResultMsg = document.querySelector("input[name=AuthResultMsg]").value;
      this.cardPaymentInfo.authToken = document.querySelector("input[name=AuthToken]").value;
      this.cardPaymentInfo.payMethod = document.querySelector("input[name=PayMethod]").value;
      this.cardPaymentInfo.mid = document.querySelector("input[name=MID]").value;
      this.cardPaymentInfo.moid = document.querySelector("input[name=Moid]").value;
      this.cardPaymentInfo.signature = document.querySelector("input[name=Signature]").value;
      this.cardPaymentInfo.amt = document.querySelector("input[name=Amt]").value;
      this.cardPaymentInfo.txTid = document.querySelector("input[name=TxTid]").value;
      this.cardPaymentInfo.nextAppURL = document.querySelector("input[name=NextAppURL]").value;
      this.cardPaymentInfo.netCancelURL = document.querySelector("input[name=NetCancelURL]").value;

      // 결제 승인 요청
      this.updatePaymentCardPC(this.cardPaymentInfo)
        .then(data => {
          //this.$log.log(data);
          if (
            data !== undefined &&
            data !== null &&
            data.payResultCode !== undefined &&
            data.payResultCode !== null &&
            data.payResultCode !== "" &&
            data.payResultCode === "3001"
          ) {
            deleteLayer(); // NICEPAY 처리중 layer 닫기
            this.paymentResultInfo = data;

            // 처리중 팝업
            this.$refs["completePaymentCardPC"].show();

            // NFT 전송 >> TODO 폴링 방식으로 변경 필요!!
            //this.$log.log("NFT 전송 시작...");
            this.transferNFT({ idxOrderPayment: this.cardPaymentInfo.idxOrderPayment })
              .then(data => {
                //this.$log.log("[completePaymentCardPC] >> ", data);
                this.paymentResultInfo = data;
                this.submitted = true;

                // 판매 법인 정보 삭제
                localStorage.removeItem("referralCorpSeller_" + this.paymentResultInfo.idxProduct);
              })
              .catch(error => {
                this.$log.error(error);
                if (error.data.reason === "BAD_REQUEST") {
                  if (error.data.resource === "NFT_TRANSFER") {
                    alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
                  } else if (error.data.resource === "NFT_TRANSFER_PROGRESS") {
                    alert("NFT 전송 시간이 지연되고 있습니다. 잠시후 다시 확인해주세요.");
                  } else {
                    alert("NFT 전송 오류가 발생하였습니다. 관리자에게 문의하세요.");
                  }
                } else {
                  alert("NFT 전송 오류가 발생하였습니다. 관리자에게 문의하세요.");
                }
              });
          } else {
            deleteLayer(); // NICEPAY 처리중 layer 닫기
            alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
          }
        })
        .catch(error => {
          deleteLayer(); // NICEPAY 처리중 layer 닫기
          this.$log.error(error);
          if (error.data.error === "NOT_FOUND" && error.data.resource === "PRODUCT_FORSALE") {
            alert("구매 가능한 작품이 존재하지 않습니다.");
          } else if (error.data.error === "NOT_FOUND" && error.data.resource === "ENTITY") {
            alert("사용자(회원) 정보가 존재하지 않습니다.");
          } else {
            alert("주문결제 오류가 발생하였습니다. 관리자에게 문의하세요.");
          }
        });
    },
    callbackNicepayClose() {
      alert("결제가 취소 되었습니다.");
    },
    checkPlatform(ua) {
      // pc, mobile 구분(가이드를 위한 샘플 함수입니다.)
      if (ua === undefined) {
        ua = window.navigator.userAgent;
      }

      ua = ua.toLowerCase();
      var platform = {};
      var matched = {};
      var userPlatform = "pc";
      var platform_match = /(ipad)/.exec(ua) || /(ipod)/.exec(ua)
        || /(windows phone)/.exec(ua) || /(iphone)/.exec(ua)
        || /(kindle)/.exec(ua) || /(silk)/.exec(ua) || /(android)/.exec(ua)
        || /(win)/.exec(ua) || /(mac)/.exec(ua) || /(linux)/.exec(ua)
        || /(cros)/.exec(ua) || /(playbook)/.exec(ua)
        || /(bb)/.exec(ua) || /(blackberry)/.exec(ua)
        || [];

      matched.platform = platform_match[0] || "";

      if (matched.platform) {
        platform[matched.platform] = true;
      }

      if (platform.android || platform.bb || platform.blackberry
        || platform.ipad || platform.iphone
        || platform.ipod || platform.kindle
        || platform.playbook || platform.silk
        || platform["windows phone"]) {
        userPlatform = "mobile";
      }

      if (platform.cros || platform.mac || platform.linux || platform.win) {
        userPlatform = "pc";
      }

      return userPlatform;
    },
    getInitPaymentInfo() {
      return {
        idx: null,
        idxProduct: null,
        idxMember: null
      };
    },
    // <<<< NICEPAY 간편결제
    goLogin() {
      this.$router.push({ name: "Login" });
    },
    goMyPage() {
      if (this.me.idx > 0) {
        this.$router.push({
          name: "MyPage",
          params: { idxMember: this.me.idx, activeTab: 0 }
        });
      }
    },
    goDrops() {
      this.$router.push({ name: "Drops" });
    },
    goArtpool() {
      this.$router.push({ name: "NftPool" });
    },
    goHome() {
      this.$router.push({ name: "Home" });
    },
    onClickShowTerms(type) {
      let routeUrl = this.$router.resolve({ path: "/terms/" + type });
      window.open(routeUrl.href, "_blank");
    },
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    replaceNewLine(description) {
      if (
        description !== undefined &&
        description !== null &&
        description !== ""
      ) {
        return description.replace(/(?:\r\n|\r|\n)/g, "<br />");
      } else {
        return "";
      }
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    }
  },
  filters: {
    currency: value => {
      if (!value) return "";
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    currencyCoin: function(value,currency){
      if(currency === "ETH"){
        return parseFloat(Math.floor(value * 1000) / 1000)
          .toFixed(3)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
      } else if(currency === "BTC") {
        return parseFloat(Math.floor(value * 100000000) / 100000000)
          .toFixed(8)
      }
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }
};
</script>
