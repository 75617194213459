<template>
  <div>
    <b-modal
      id="payment-wc-modal-2"
      ref="payment-wc-modal-2"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-black"
      @hide="closeModal"
    >
      <CRow>
        <CCol class="col-12">
          <CLink @click="hideConfirm">
            <CIcon name="cil-x" class="float-right" size="lg" />
          </CLink>
        </CCol>
      </CRow>

      <div class="w-100 d-flex justify-content-center align-items-center">
        <img src="/img/threespace/company/wallet_connect_logo_color.png" width="34" class="float-left mr-1"/>
        <h5 class="ml-2 mb-0">{{ $t("wallet.modal.walletConnectTitle") }}</h5>
      </div>
      <div class="w-100 d-flex justify-content-center py-5">
        walletConnect와 연결된 지갑에서 트랜젝션 확인 후 결제를 진행해 주세요.
      </div>
      <div class="w-100 text-center">
        <p>
          <span class="text-gray mr-2">{{ $t("wallet.modal.remainTime") }}</span>
          <span class="text-danger">{{ time }}</span>
        </p>
      </div>
    </b-modal>

    <PaymentWalletConnect3 :payment-result-info="paymentResultInfo" ref="paymentWalletConnect3" />
  </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from "vuex";
const paymentHelper = createNamespacedHelpers("payment");
import Web3 from "web3";

import PaymentWalletConnect3 from "@/views/threespace/payment/PaymentWalletConnect3";
import WalletConnectUtil from "@/mixins/WalletConnectUtil";

export default {
  name: "PaymentWalletConnect2",
  components: {
    PaymentWalletConnect3
  },
  props: {
    paymentInfo: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [WalletConnectUtil],
  data: function() {
    return {
      isTimeout: false,
      date: this.$moment(60 * 3 * 1000),
      paymentResultInfo: {},
      paymentType: "",
      resultStatus: "",
    };
  },
  mounted() {},
  computed: {
    ...mapState(["wallet"]),
    time: function () {
      return this.date.format("mm : ss");
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }
  },
  methods: {
    ...paymentHelper.mapActions([
      "completePaymentEthPaycontract",
      "prepareEthPayment",
      "prepareMaticPayment",
      "updatePaymentEth",
    ]),
    showWalletConnectPayment() {
      if (this.paymentType === "ETH") {
        if (this.paymentInfo.amountCoin >= parseFloat(Math.floor(this.wallet.balance.ethereum.quantity * 1000) / 1000).toFixed(3)) {
          this.hideReserveCancel();
          alert(this.$t("payment.insufficientFunds"));
          return
        } else {
          this.$store.commit("setShowLoading", {
            show: true,
            msg: this.$t("loading.payment.walletConnect.prepared")
          }); // loading overlay
          this.prepareEthPayment({
            payment: this.paymentInfo.idxProduct,
            currency: "ETH",
          })
            .then((receipt) => {
              this.$log.info("[prepareEthPayment] receipt :", receipt);
              this.$store.commit("setShowLoading", {show: false, msg: ""}); // loading overlay
              this.sendNext(receipt);
            })
            .catch((error) => {
              this.$log.error(error);
              this.hideReserveCancel();
              this.$store.commit("setShowLoading", {show: false, msg: ""}); // loading overlay
              alert(this.$t("payment.payError.fail"));
            });
        }
      } else if (this.paymentType === "MATIC") {
        this.$store.commit("setShowLoading", {
          show: true,
          msg: this.$t("loading.payment.walletConnect.prepared")
        }); // loading overlay
        this.prepareMaticPayment({
          payment: this.paymentInfo.idxProduct,
          currency: "MATIC",
        })
          .then((receipt) => {
            this.$log.info("[prepareMaticPayment] receipt :", receipt);
            this.$store.commit("setShowLoading", {show: false, msg: ""}); // loading overlay
            this.sendNext(receipt);
          })
          .catch((error) => {
            this.$log.error(error);
            this.hideReserveCancel();
            this.$store.commit("setShowLoading", {show: false, msg: ""}); // loading overlay
            alert(this.$t("payment.payError.fail"));
          });
      }

    },
    async sendNext(receipt) {
      if (receipt.buyable) {
        // const web3 = new Web3(window.ethereum);
        this.isTimeout = false;
        this.date = this.$moment(60 * 3 * 1000); // 3분
        clearInterval(this.totalTimer);
        // 결제 대기 타이머
        this.setTotalTimer();
        this.$refs["payment-wc-modal-2"].show();

        const provider = await this.getWalletConnectProvider();

        if(provider){
          const web3 = new Web3(provider);
          const data = web3.eth.abi.encodeFunctionCall(
            {
              "name": "purchase",
              "type": "function",
              "inputs": [
                {
                  "internalType": "address",
                  "name": "contractAddr",
                  "type": "address"
                },
                {
                  "internalType": "uint256",
                  "name": "tokenId",
                  "type": "uint256"
                }
              ],
            },
            [receipt.nftContract, receipt.tokenId]
          );

          const tx = {
            from: receipt.buyerAddress,
            to: process.env.VUE_APP_ETHEREUM_PAY_CONTRACT,
            value: parseInt(receipt.amount).toString(16),
            data
          }

          // @TODO sendTransaction 에서 txHash를 받을수 있으면 로딩바 사용
          // this.$refs["payment-wc-modal-2"].hide();
          // this.$store.commit("setShowLoading", {
          //   show: true, 
          //   msg: this.$t("loading.payment.walletConnect.progressing")
          // }); // loading overlay

          provider.request({
            method: 'eth_sendTransaction',
            params: [tx]
          })
            .then((result)  => {
              this.$log.info("WalletConnect sendTransaction : ", result);
              clearInterval(this.totalTimer);
              this.isTimeout = true;
              this.requestComplate();
            })
            .catch((error) => {
              this.$log.error("WalletConnect sendTransaction : ", error);
              this.hideReserveCancel();
              this.$store.commit("setShowLoading", {show: false, msg: ""}); // loading overlay
              alert(this.$t("payment.payError.fail"));
            })
        }
      } else {
        let errorMessage = "현재 이더리움 네트워크 상태가 원활하지 않습니다. 잠시 후 다시 시도해 주세요.";
        if (receipt.sold) {
          errorMessage = "이미 팔린 작품입니다.";
        } else if (!receipt.buyable) {
          errorMessage = "현재 구매할 수 없는 작품입니다";
        }
        alert(errorMessage);
        this.hideReserveCancel();
      }
    },
    getCachedSession(){
      const local = localStorage ? localStorage.getItem("walletconnect") : null;

      let session = null;
      if (local) {
        session = JSON.parse(local);
      }
      return session;
    },
    setTotalTimer() {
      if (!this.isTimeout) {
        this.totalTimer = setInterval(() => {
          this.date = this.date.clone().subtract(1, "seconds");
          if (this.time === "00 : 00") {
            clearInterval(this.totalTimer);
            this.$log.log("timeout..."); //TODO
            this.hideReserveCancel();
          }
        }, 1000);
      }
    },
    requestComplate() {
      this.completePaymentEthPaycontract(this.paymentInfo)
        .then(data => {
          //this.$log.log("[completePaymentEthPaycontract] >> ", data);
          this.paymentResultInfo = data;
          this.$refs["paymentWalletConnect3"].show();
          this.$refs["payment-wc-modal-2"].hide();

          // 판매 법인 정보 삭제
          localStorage.removeItem("referralCorpSeller_" + this.paymentResultInfo.idxProduct);
        })
        .catch(error => {
          this.$log.error(error);
          this.$refs["payment-wc-modal-2"].hide();
          alert(this.$t("payment.payError.waiting"));
        });
    },
    show(paymentType) {
      this.isTimeout = false;
      this.paymentType = paymentType;
      this.showWalletConnectPayment();
    },
    hideReserveCancel() {
      const requestInfo = {
        idxProduct: this.paymentInfo.idxProduct,
        idxOrderPayment: this.paymentInfo.idxOrderPayment,
        status: "RESERVE_CANCEL"
      };
      // 작품 상태(예약취소, 판매중) 업데이트
      this.updatePaymentEth(requestInfo)
        .then(data => {
          this.$refs["payment-wc-modal-2"].hide();
        })
        .catch(error => {
          this.$refs["payment-wc-modal-2"].hide();
          this.$log.error(error);
        });
    },
    hideConfirm() {
      if (confirm(this.$t("payment.payCancelMsg"))) {
        this.hideReserveCancel();
      }
    },
    closeModal() {
      clearInterval(this.totalTimer);
    },
  },
  destroyed() {
    clearInterval(this.totalTimer);
  }
};
</script>
