<template>
  <div>
    <b-modal
      id="order-payment-modal-3"
      ref="order-payment-modal-3"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      body-class="pb-4 text-dark"
    >
      <CRow>
        <CCol class="col-12 py-2">
          <CLink @click="hide()">
            <CIcon name="cil-x" class="float-right" size="lg" />
          </CLink>
        </CCol>
      </CRow>

      <CRow class="mt-4">
        <CCol class="col-12 text-center">
          <img src="/img/threespace/payment/complate_icon.png" class="mb-4" />
          <p class="font-xl font-weight-bold">{{ $t("payment.vbank3.completeOrder") }}</p>
          <p>
            {{ $t("payment.vbank3.completeMsgFirst") }}
            <a
              class="d-inline-block text-dark cursor-pointer"
              @click="goMyPage"
            >
              <b><u>{{ $t("payment.vbank3.completeMsgLink") }}</u></b> </a
            >{{ $t("payment.vbank3.completeMsgLast") }}
          </p>
          <p>
            {{ $t("payment.vbank3.orderId") }} : {{ paymentResultInfo.moid }}<br />
            {{ $t("payment.vbank3.orderDate") }} : {{ paymentResultInfo.createdAt }}
          </p>
        </CCol>
      </CRow>

      <CRow class="mt-4">
        <CCol class="col-5 border-right pl-5">
          <div>{{ $t("payment.artwork.title") }}</div>
          <p class="font-lg font-weight-bold">
            <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
              {{ paymentResultInfo.productName || paymentResultInfo.productNameEn }}
            </template>
            <template v-else>
              {{ paymentResultInfo.productNameEn || paymentResultInfo.productName }}
            </template>
          </p>
          <div>{{ $t("payment.artwork.price") }}</div>
          <p class="font-lg font-weight-bold">
            {{ paymentResultInfo.amt | currency }} KRW
          </p>
        </CCol>
        <CCol class="col-7 pr-5">
          <div class="font-weight-bold">{{ $t("payment.payMethod") }}</div>
          <p>
            {{ $t("payment.vbank3.vbank") }}<br />{{ $t("payment.vbank2.account") }} : {{ paymentResultInfo.fnName }}
            <b>{{ paymentResultInfo.vbankNum }}</b>
          </p>
          <p>
            <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
              <b>{{ paymentResultInfo.vbankExpDatetime }}</b>
            </template>
            {{ $t("payment.vbank3.deadlineMsg") }}
            <template v-if="activeLanguage !== 'ko'">
              : <b>{{ paymentResultInfo.vbankExpDatetime }}</b>
            </template>
          </p>
          <p>
            <b>{{ $t("payment.vbank2.cashReceipt") }}</b> : <span v-html="getReceiptInfo()"></span>
          </p>
        </CCol>
      </CRow>

      <CRow class="mt-5 mb-4 justify-content-center d-flex">
        <CCol class="col-3">
          <b-button
            variant="secondary"
            size="lg"
            squared
            class="btn-block mr-2"
            @click="goMyPage"
          >
            {{ $t("payment.goto.myPage") }}
          </b-button>
        </CCol>
        <CCol class="col-3">
          <b-button
            variant="primary"
            size="lg"
            squared
            class="btn-block ml-2"
            @click="goArtpool"
          >
            <span class="text-white">{{ $t("payment.goArtPool") }}</span>
          </b-button>
        </CCol>
      </CRow>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PaymentVBank3",
  props: {
    paymentResultInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    return {
      defaultLanguage: "en"
    };
  },
  computed: {
    ...mapState({
      me: state => {
        return state.auth.me || {};
      }
    }),
    activeLanguage() {
      return this.getActiveLanguage();
    }
  },
  mounted() {},
  methods: {
    getReceiptInfo() {
      //this.$log.log("[getReceiptInfo] >> ", this.paymentResultInfo);
      let receiptInfo = this.$t("payment.vbank3.noData");
      if (
        this.paymentResultInfo.receiptType !== undefined &&
        this.paymentResultInfo.receiptType !== null &&
        this.paymentResultInfo.receiptType !== ""
      ) {
        if (this.paymentResultInfo.receiptType === "0") {
          receiptInfo = this.$t("payment.vbank3.notIssued");
        } else if (this.paymentResultInfo.receiptType === "1") {
          receiptInfo = this.$t("payment.vbank2.incomeDeduction") + " " + this.paymentResultInfo.receiptTypeNo;
        } else if (this.paymentResultInfo.receiptType === "2") {
          receiptInfo = this.$t("payment.vbank2.proopOfExpense") + " " + this.paymentResultInfo.receiptTypeNo;
        }
      }
      return receiptInfo;
    },
    show() {
      this.$refs["order-payment-modal-3"].show();
    },
    hide() {
      this.$router.push({ name: "Drops" });
      this.$refs["order-payment-modal-3"].hide();
    },
    goMyPage() {
      if (this.me.idx > 0) {
        //this.$router.push("/mypage/" + this.me.idx).catch(() => {});
        this.$router.push({
          name: "MyPage",
          params: { idxMember: this.me.idx, activeTab: 2 }
        });
      } else {
        this.$router.push({ name: "Drops" });
      }
      this.$refs["order-payment-modal-3"].hide();
    },
    goDrops() {
      this.$router.push({ name: "Drops" });
      this.$refs["order-payment-modal-3"].hide();
    },
    goArtpool() {
      this.$router.push({ name: "NftPool" });
      this.$refs["order-payment-modal-3"].hide();
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || this.defaultLanguage;
    }
  },
  filters: {
    currency: value => {
      if (!value) return "";
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }
};
</script>
