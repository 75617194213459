<template>
  <div>
    <b-modal
      id="payment-wc-modal-1"
      ref="payment-wc-modal-1"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-black"
      @hide="closeModal"
    >
      <CRow>
        <CCol class="col-12">
          <CLink @click="hide">
            <CIcon name="cil-x" class="float-right" size="lg" />
          </CLink>
        </CCol>
      </CRow>

      <div class="w-100 d-flex justify-content-center align-items-center">
        <img src="/img/threespace/company/wallet_connect_logo_color.png" width="34" class="float-left mr-1"/>
        <h5 class="ml-2 mb-0">
          {{ $t("payment.walletConnect.connectTitle") }}
        </h5>
      </div>
      <div class="text-center mt-3 px-5">
        <p>
          {{ $t("payment.walletConnect.connectDesc") }}
        </p>
      </div>
      <div class="my-4 d-flex justify-content-center">
        <b-button squared class="px-4" @click="hide">
          <span>{{ $t("button.cancel") }}</span>
        </b-button>
        <b-button
          variant="primary"
          squared
          class="ml-3 px-4"
          @click="showPaymentWalletConnect2"
          :disabled="timeout"
        >
          <span class="text-white">{{ $t("button.yesPayment") }}</span>
        </b-button>
      </div>
    </b-modal>

    <PaymentWalletConnect2
      :payment-info="paymentInfo"
      ref="paymentWalletConnect2"
    />
    <WalletConnect ref="walletConnect"  @wallet-info="setWalletInfo"></WalletConnect>
  </div>
</template>

<script>
import {createNamespacedHelpers, mapActions, mapState} from "vuex";
import PaymentWalletConnect2 from "./PaymentWalletConnect2";
import WalletConnect from "@/utils/walletConnect"
import WalletConnectUtil from "@/mixins/WalletConnectUtil"

const paymentHelper = createNamespacedHelpers("payment");


export default {
  name: "PaymentWalletConnect1",
  components: {
    PaymentWalletConnect2,
    WalletConnect
  },
  props: {
    orderPaymentInfo: {
      type: Object,
      default: () => {}
    },
    memberInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      paymentInfo: this.getInitData(),
      time: 60,
      timeout: false,
      paymentType: "",
    };
  },
  mixins: [WalletConnectUtil],
  mounted() {},
  computed: {
    ...mapState("auth",["me"]),
    ...mapState("wallet",["provider"]),
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
  },
  methods: {
    ...paymentHelper.mapActions(["updatePaymentEth", "updatePaymentMatic"]),
    show(paymentType) {
      this.time = 60;
      this.timeout = false;
      this.setTotalTimer();
      if (paymentType != undefined) {
        this.paymentType = paymentType;
      }
      this.paymentInfo = this.getInitData();
      this.$refs["payment-wc-modal-1"].show();
    },
    getCachedSession(){
      return localStorage ? localStorage.getItem("pairingTopic") : null;
    },
    async showPaymentWalletConnect2(walletInfo) {

      const provider = await this.getWalletConnectProvider();

      let chainId = walletInfo.chainId;
      if (provider) {
        if (this.checkValidAddress(provider.accounts[0], this.memberInfo, provider.chainId)) {
          alert("Check your connected wallet address again");
          return;
        }
        chainId = provider.chainId;
      } else {
        if(walletInfo.addr === undefined || walletInfo.addr === null){
          this.$refs.walletConnect.onClick();
          return;
        }else {
          if(this.checkValidAddress(walletInfo.addr, this.memberInfo, chainId)){
            alert("Check your connected wallet address again");
            return;
          }
        }
        chainId = walletInfo.chainId;
      }

      if (this.checkValidChain(chainId)) {
        alert("Check your connected network again");
        return;
      }

      this.paymentInfo.idxProduct = this.orderPaymentInfo.idxProduct;
      this.paymentInfo.idxMember = this.orderPaymentInfo.idxMember;
      this.paymentInfo.idxOrderPayment = this.orderPaymentInfo.idxOrderPayment;

      if (this.paymentType === "ETH") {
        // 작품 상태(예약중) 업데이트
        this.updatePaymentEth(this.paymentInfo)
          .then((data) => {
            this.paymentInfo.fromAddr = data.fromAddr;
            this.paymentInfo.toAddr = data.toAddr;
            this.paymentInfo.amountCoin = data.amountCoin;

            clearInterval(this.totalTimer);
            this.$refs["paymentWalletConnect2"].show(this.paymentType);
            this.$refs["payment-wc-modal-1"].hide();
          })
          .catch(error => {
            this.$log.error(error);
            alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
            clearInterval(this.totalTimer);
            this.cancelPayment();
          });
      } else if (this.paymentType === "MATIC") {
        // 작품 상태(예약중) 업데이트
        this.updatePaymentMatic(this.paymentInfo)
          .then((data) => {
            this.paymentInfo.fromAddr = data.fromAddr;
            this.paymentInfo.toAddr = data.toAddr;
            this.paymentInfo.amountCoin = data.amountCoin;

            clearInterval(this.totalTimer);
            this.$refs["paymentWalletConnect2"].show(this.paymentType);
            this.$refs["payment-wc-modal-1"].hide();
          })
          .catch(error => {
            this.$log.error(error);
            alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
            clearInterval(this.totalTimer);
            this.cancelPayment();
          });
      }
    },
    hide() {
      if (confirm(this.$t("payment.payCancelMsg"))) {
        clearInterval(this.totalTimer);
        this.$refs["payment-wc-modal-1"].hide();
      }
    },
    closeModal() {
      clearInterval(this.totalTimer);
    },
    getInitData() {
      return {
        idxProduct: null,
        idxMember: null,
        idxOrderPayment: null,
        status: "RESERVE",
        fromAddr: null,
        toAddr: null,
        amountCoin: null,
        coinCurrencyType: this.paymentType,
      }
    },
    cancelPayment() {
      const requestInfo = {
        idxProduct: this.paymentInfo.idxProduct,
        idxOrderPayment: this.paymentInfo.idxOrderPayment,
        status: "RESERVE_CANCEL"
      };
      // 작품 상태(예약취소, 판매중) 업데이트
      this.updatePaymentEth(requestInfo);
      this.$refs["payment-wc-modal-1"].hide();
    },
    setTotalTimer() {
      this.totalTimer = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          clearInterval(this.totalTimer);
          this.timeout = true;
        }
      }, 1000);
    },
    setWalletInfo(data) {
      this.showPaymentWalletConnect2(data);
    }
  },
};
</script>
