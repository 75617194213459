import { render, staticRenderFns } from "./PaymentMetaMask2.vue?vue&type=template&id=8292a9de&"
import script from "./PaymentMetaMask2.vue?vue&type=script&lang=js&"
export * from "./PaymentMetaMask2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports