<template>
  <div>
    <b-modal
      id="payment-toss-transfer-2"
      ref="payment-toss-transfer-2"
      class="payment_complate_modal_wrap"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <CRow>
        <CCol class="col-12 py-2">
          <CLink @click="hide()">
            <img class="float-right" src="/img/threespace/icon/x-icon.png" />
          </CLink>
        </CCol>
      </CRow>

      <div class="payment_complete_modal">
        <div class="title">
          <p v-html="$t('payment.transfer.completeOrder')"></p>
        </div>
        <div class="text-center">
          <p>
            {{ $t("payment.transfer.completeMsgFirst") }}
            <a
              class="d-inline-block text-dark cursor-pointer"
              @click="goMyPage"
            >
              <b><u>{{ $t("payment.transfer.completeMsgLink") }}</u></b>
            </a>{{ $t("payment.transfer.completeMsgLast") }}
          </p>
          <p>
            {{ $t("payment.transfer.orderId") }} : {{ paymentResultInfo.moid }}<br />
            {{ $t("payment.transfer.orderDate") }} : {{ paymentResultInfo.createdAt }}
          </p>
        </div>
        <div class="content">
          <b-table-simple borderless>
            <!-- 작품명 -->
            <tr>
              <th>{{ $t("payment.artwork.title") }}</th>
              <td>
                <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                  {{ paymentResultInfo.productName || paymentResultInfo.productNameEn }}
                </template>
                <template v-else>
                  {{ paymentResultInfo.productNameEn || paymentResultInfo.productName }}
                </template>
              </td>
            </tr>
            <!-- 가격 -->
            <tr>
              <th>{{ $t("payment.artwork.price") }}</th>
              <td>
                {{ paymentResultInfo.amt | currency }} KRW
              </td>
            </tr>
            <!-- 결제수단 -->
            <tr>
              <th>{{ $t("payment.payMethod") }}</th>
              <td>
                {{ $t("payment.transfer.type") }}
              </td>
            </tr>
            <!-- 현금영수증 -->
            <tr>
              <th>{{ $t("payment.transfer.cashReceipt") }}</th>
              <td>
                <span v-html="getReceiptInfo()" />
                <span v-if="paymentResultInfo.cashReceiptIssueNumber !== undefined
                        && paymentResultInfo.cashReceiptIssueNumber !== null
                        && paymentResultInfo.cashReceiptIssueNumber !== ''">
                  <br/>{{ $t("payment.transfer.cashReceiptMsg") }}
                </span>
              </td>
            </tr>
          </b-table-simple>
        </div>
        <div class="btn_box">
          <b-button
            class="goCollection"
            @click="goMyPage"
          >
            {{ $t("payment.goto.profile") }}
          </b-button>
          <b-button
            class="goArtpool"
            @click="goDrops"
          >
            {{ $t("payment.goto.gallery") }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PaymentTossTransfer2",
  props: {
    paymentResultInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    return {
      defaultLanguage: "en"
    };
  },
  computed: {
    ...mapState({
      me: state => {
        return state.auth.me || {};
      }
    }),
    activeLanguage() {
      return this.getActiveLanguage();
    }
  },
  mounted() {},
  methods: {
    getReceiptInfo() {
      let receiptInfo = this.$t("payment.transfer.noData");
      if (
        this.paymentResultInfo.receiptType !== undefined &&
        this.paymentResultInfo.receiptType !== null &&
        this.paymentResultInfo.receiptType !== ""
      ) {
        if (this.paymentResultInfo.receiptType === "0") {
          receiptInfo = this.$t("payment.transfer.notIssued");
        } else if (this.paymentResultInfo.receiptType === "1"
          && this.paymentResultInfo.cashReceiptIssueNumber !== undefined
          && this.paymentResultInfo.cashReceiptIssueNumber != null
          && this.paymentResultInfo.cashReceiptIssueNumber != "") {
          receiptInfo = this.$t("payment.transfer.cashReceiptIssue") + "(" + this.$t("payment.transfer.incomeDeduction") + ")";
        } else if (this.paymentResultInfo.receiptType === "2"
          && this.paymentResultInfo.cashReceiptIssueNumber !== undefined
          && this.paymentResultInfo.cashReceiptIssueNumber != null
          && this.paymentResultInfo.cashReceiptIssueNumber != "") {
          receiptInfo = this.$t("payment.transfer.cashReceiptIssue") + "(" + this.$t("payment.transfer.proopOfExpense") + ")";
        }
      }
      return receiptInfo;
    },
    show() {
      this.$refs["payment-toss-transfer-2"].show();
    },
    hide() {
      this.$router.push({ name: "Drops" });
      this.$refs["payment-toss-transfer-2"].hide();
    },
    goMyPage() {
      if (this.me.idx > 0) {
        this.$router.push({
          name: "MyPage",
          params: { idxMember: this.me.idx, activeTab: 2 }
        }); // TODO activeTab 표시안됨
      } else {
        this.$router.push({ name: "Drops" });
      }
      this.$refs["payment-toss-transfer-2"].hide();
    },
    goDrops() {
      this.$router.push({ name: "Drops" });
      this.$refs["payment-toss-transfer-2"].hide();
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || this.defaultLanguage;
    }
  },
  filters: {
    currency: value => {
      if (!value) return "";
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }
};
</script>
