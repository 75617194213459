<template>
  <div>
    <b-modal
      id="payment-klip-modal-1"
      ref="payment-klip-modal-1"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-black"
      @hide="closeModal"
    >
      <CRow>
        <CCol class="col-12">
          <CLink @click="hide">
            <CIcon name="cil-x" class="float-right" size="lg" />
          </CLink>
        </CCol>
      </CRow>

      <div class="w-100 d-flex justify-content-center align-items-center">
        <img src="/img/threespace/company/kakao_klip_logo_color.png" width="50" class="float-left"/>
        <h5 class="ml-2 mb-0">
          {{ $t("payment.klip.connectTitle") }}
        </h5>
      </div>
      <div class="text-center mt-3 px-5">
        <p>
          {{ $t("payment.klip.connectDesc") }}
        </p>
      </div>
      <div class="my-4 d-flex justify-content-center">
        <b-button
          squared
          class="px-4"
          @click="hide"
        >
          <span>{{ $t("button.cancel") }}</span>
        </b-button>
        <b-button
          variant="primary"
          squared
          class="ml-3 px-4"
          @click="showPaymentKlip2"
          :disabled="timeout"
        >
          <span class="text-white">{{ $t("button.yesPayment") }}</span>
        </b-button>
      </div>
    </b-modal>

    <PaymentKlip2 :klay-payment-info="klayPaymentInfo" ref="paymentKlip2" />
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
const paymentHelper = createNamespacedHelpers("payment");

import PaymentKlip2 from "@/views/threespace/payment/PaymentKlip2";

export default {
  name: "PaymentKlip1",
  components: {
    PaymentKlip2
  },
  props: {
    orderPaymentInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      klayPaymentInfo: this.getInitData(),
      time: 60,
      timeout: false,
      paymentType: "",
    };
  },
  mounted() {},
  computed: {
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }
  },
  methods: {
    ...paymentHelper.mapActions(["updatePaymentKlay"]),
    showPaymentKlip2() {
      this.klayPaymentInfo.idxProduct = this.orderPaymentInfo.idxProduct;
      this.klayPaymentInfo.idxMember = this.orderPaymentInfo.idxMember;
      this.klayPaymentInfo.idxOrderPayment = this.orderPaymentInfo.idxOrderPayment;
      //this.$log.log("[showPaymentKlip2] >> ", this.klayPaymentInfo);

      // 작품 상태(예약중) 업데이트
      this.updatePaymentKlay(this.klayPaymentInfo)
        .then(data => {
          //this.$log.log("[updatePaymentKlay] >> ", data);

          this.klayPaymentInfo.fromAddr = data.fromAddr;
          this.klayPaymentInfo.toAddr = data.toAddr;
          this.klayPaymentInfo.amountCoin = data.amountCoin;

          clearInterval(this.totalTimer);
          this.$refs["paymentKlip2"].show(this.paymentType);
          this.$refs["payment-klip-modal-1"].hide();
        })
        .catch(error => {
          this.$log.error(error);
          alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
          clearInterval(this.totalTimer);
          this.$refs["payment-klip-modal-1"].hide();
        });
    },
    show(paymentType) {
      this.time = 60;
      this.timeout = false;
      this.setTotalTimer();
      if (paymentType != undefined) {
        this.paymentType = paymentType;
      }
      this.klayPaymentInfo = this.getInitData();
      this.$refs["payment-klip-modal-1"].show();
    },
    hide() {
      if (confirm(this.$t("payment.payCancelMsg"))) {
        clearInterval(this.totalTimer);
        this.$refs["payment-klip-modal-1"].hide();
      }
    },
    closeModal() {
      clearInterval(this.totalTimer);
    },
    getInitData() {
      return {
        idxProduct: null,
        idxMember: null,
        idxOrderPayment: null,
        status: "RESERVE",
        fromAddr: null,
        toAddr: null,
        amountCoin: null,
        coinCurrencyType: this.paymentType,
      }
    },
    setTotalTimer() {
      this.totalTimer = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          clearInterval(this.totalTimer);
          this.timeout = true;
        }
      }, 1000);
    }
  }
};
</script>
